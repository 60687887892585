import { useEffect, useState } from 'react';
import CakeTwoToneIcon from '@mui/icons-material/CakeTwoTone';
import { AppBar, Box, IconButton } from '@mui/material';
import LongMenu from './Menu';
import { useLanguage } from '../../context/LanguageContext';
import { IUser } from '../../models/IUser';
import { authAPI } from '../../services/AuthServices';
import { useNavigate } from 'react-router-dom';
import { userAPI } from '../../services/UserServices';
import { useStyles } from './headerStyles';

interface Props {
    user: IUser | undefined;
    setUser: (u: IUser | undefined) => void;
    setIsLoginStart: (data: boolean) => void;
    setUserDataIsLoaded: (data: boolean) => void;
    setIsLoggedIn: (data: boolean) => void;
}

interface IError {
    status: number;
    data: string;
}

function Header({ user, setUser, setIsLoginStart, setUserDataIsLoaded, setIsLoggedIn }: Props) {
    const [isLogOut, setIsLogOut] = useState(false);
    const [userName, setUserName] = useState('');

    const { language, changeLanguage } = useLanguage();
    const history = useNavigate();
    const { menuWrapper, iconStyle, bar, headerWrapper, leftSideWrapper, rightSideWrapper, nameStyle } = useStyles(user);

    // api
    const { data: userData, error: getUserDataError, isLoading: getUserDataIsLoading, isSuccess: getUserDataIsSuccess } = userAPI.useGetUserDataQuery();
    const [logOut, { error: logOutError, isSuccess: logOutIsSuccess }] = authAPI.useLogOutMutation();

    // get user name if needed
    const extractName = (str: string) => {
        return str.replace(/^(.)(.*)$/, (_, firstLetter, rest) => {
            const res = firstLetter.toUpperCase() + rest.toLowerCase();
            return res.split('@')[0];
        });
    };

    // method to control a language
    const handleLang = () => {
        if (language === 'en') changeLanguage('ru');
        else changeLanguage('en');
    };

    // logout method
    const handleLogOut = () => {
        setIsLogOut(true);
        logOut();
        history('/');
        setUser(undefined);
        setUserName('');
    };

    // router
    const router = () => {
        history('/chai');
    };

    // set user data after receive
    useEffect(() => {
        if (userData !== undefined && !isLogOut) {
            setUser(userData);
            setIsLoginStart(false);
            setUserDataIsLoaded(true);
            setIsLoggedIn(true);
        }
        if (isLogOut) {
            setIsLoggedIn(false);
        }
    }, [userData, isLogOut]);

    useEffect(() => {
        if (getUserDataError !== undefined) {
            const err = getUserDataError as IError;
            if (err.status === 400) {
                setUserDataIsLoaded(true);
            }
            if (err.data === 'Unauthorized.') {
                setUserDataIsLoaded(true);
            }
        }
    }, [getUserDataError]);

    // handle user name next to menu
    useEffect(() => {
        setUserName(!!user ? (user.name.includes('@') ? extractName(user.name) : user.name) : '');
    }, [user]);

    useEffect(() => {
        !logOutError && logOutIsSuccess && setIsLoginStart(false);
    }, [logOutError, logOutIsSuccess]);

    return (
        <AppBar sx={bar}>
            <Box sx={headerWrapper}>
                <Box sx={{ ...leftSideWrapper, ...menuWrapper }} onClick={router}>
                    <CakeTwoToneIcon />
                </Box>

                <Box sx={rightSideWrapper}>
                    <Box sx={nameStyle}>{userName}</Box>
                    <Box sx={menuWrapper}>
                        <LongMenu logout={handleLogOut} />
                    </Box>
                    <IconButton sx={iconStyle} onClick={handleLang}>
                        <Box component="span">{language}</Box>
                    </IconButton>
                </Box>
            </Box>
        </AppBar>
    );
}

export default Header;
