import { useEffect, useRef, useState } from 'react';
import { Box, Container, Grid } from '@mui/material';
import Header from './components/header/Header';
import NavBar from './components/navBar/NavBar';
import Routers from './components/routes/Router';
import { IUser } from './models/IUser';
import Login from './components/login/Login';
import { useStyles } from './appStyles';

function App() {
    const headerSizeInRem = 3;
    const [user, setUser] = useState<IUser | undefined>(undefined);
    const [userDataIsLoaded, setUserDataIsLoaded] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoginStart, setIsLoginStart] = useState(false);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [fs, setFS] = useState(16);
    const ref = useRef(null);

    //style
    const { appWrapper, appWrapperContent, navBarWrapper, contentWrapper } = useStyles({ windowHeight, headerSizeInRem, fs });

    // check the window height and font size
    useEffect(() => {
        function handleResize() {
            setWindowHeight(window.innerHeight);
        }
        window.addEventListener('resize', handleResize);

        const fontSize = ref.current ? window.getComputedStyle(ref.current).fontSize : '16px';
        const fontSizeValue = typeof fontSize === 'string' ? fontSize.match(/\d+/) : null;
        const number = fontSizeValue ? parseInt(fontSizeValue[0]) : 16;

        setFS(number);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Box sx={appWrapper} ref={ref}>
            <Grid item md={12}>
                <Header setUser={setUser} user={user} setIsLoginStart={setIsLoginStart} setUserDataIsLoaded={setUserDataIsLoaded} setIsLoggedIn={setIsLoggedIn} />
            </Grid>
            <Box sx={contentWrapper}>
                {isLoggedIn ? (
                    <Container>
                        <Box sx={navBarWrapper}>
                            <Grid item md={12}>
                                <NavBar />
                            </Grid>
                        </Box>

                        <Grid item md={12}>
                            <Box sx={appWrapperContent} id="contentPageContainer">
                                <Routers />
                            </Box>
                        </Grid>
                    </Container>
                ) : (
                    <Login
                        setUser={setUser}
                        isLoginStart={isLoginStart}
                        setIsLoggedIn={setIsLoggedIn}
                        setIsLoginStart={setIsLoginStart}
                        userDataIsLoaded={userDataIsLoaded}
                        isLoggedIn={isLoggedIn}
                    />
                )}
            </Box>
        </Box>
    );
}

export default App;
