import { IRecipe } from '../models/Recipe/IRecipe';
import { DTO_STATE, RECIPE_SIZE, RECIPE_SIZE_SPECIAL } from './dictionary';
import { ICustomer } from '../models/Customers/ICustomer';

// TODO: use a language version to change the format to that language
export const getDateTime: (date: string | undefined) => { date: string; time: string } | undefined = (date) => {
    let result = {
        date: '',
        time: '',
    };

    const dateTime = date?.toString().replace('T', ' ').slice(0, 16);

    if (dateTime) {
        let temp = dateTime?.split(' ');
        let date = temp[0].split('-');
        result.date = `${date[2]}.${date[1]}.${date[0]}`;
        result.time = temp[1];
    }

    return result;
};

export const getCalendarDate = (calendar: string): string => {
    let temp = calendar?.split('/');

    return `${temp[2]}.${temp[1]}.${temp[0]}`;
};

export const getRecipeSize = (recipe: IRecipe): string[] => {
    // TODO: need to get user email for check who use application
    // only for Natali
    // const itemId = 11;
    // const qty = 3;
    // const parts = recipe.parts;
    // let contains = false;
    // parts?.some((p) => p.items?.some((i) => i.itemId === itemId));
    // recipe.parts?.forEach((p) =>
    //     p.items?.forEach((i) => {
    //         if (i.itemId === itemId) {
    //             if (i.proportions.some((p) => p.quantity === qty)) {
    //                 contains = true;
    //             }
    //         }
    //     })
    // );

    if (recipe.flagSpecial) {
        return RECIPE_SIZE_SPECIAL;
    }

    return RECIPE_SIZE;
};

export const getMessageWithOrderNumber = (orderNumber: string, lang?: string): string => {
    /* Template for Natali. Need to save templates in DB user settings table. 
        And user can create template
        TODO: set user with settings in a global context
                settings will be able to get from any application part*/

    if (lang === 'ukr') {
        return (
            'Мій номер рахунку для переказу авансу\n\r' +
            '03 1240 6449 1111 0011 3734 6560\n\r' +
            'Odbiorca: Natalia Zhyvotovska\n\r' +
            'Або blik на номер телефону: 692 626 672\n\r' +
            `При оплаті прошу вказати номер замовлення: ${orderNumber}\n\r` +
            'Підтвердження переказу вишліть,будь ласка,сюди 😉'
        );
    }

    if (lang === 'rus') {
        return (
            'Мой номер счёта для перевода аванса\n\r' +
            '03 1240 6449 1111 0011 3734 6560\n\r' +
            'Odbiorca: Natalia Zhyvotovska\n\r' +
            'Либо blik на номер: 692 626 672\n\r' +
            `В названии/назначении платежа прошу указать номер заказа: ${orderNumber}\n\r` +
            'Подтверждение оплаты отправьте, пожалуйста, сюда в переписку🤗'
        );
    }

    if (lang === 'pl') {
        return (
            'Mój numer konta do przelewu zaliczki\n\r' +
            '03 1240 6449 1111 0011 3734 6560\n\r' +
            'Odbiorca: Natalia Zhyvotovska\n\r' +
            'Lub blik na numer: 692 626 672\n\r' +
            `W tytule przelewu proszę wpisać numer zamówienia: ${orderNumber}\n\r` +
            'Potwierdzenie płatności proszę przesłać tutaj w wiadomości 🤗'
        );
    }

    return `${orderNumber}`;
};

export function compareObjects(obj1: any, obj2: any) {
    for (const key in obj1) {
        if (key === 'state' || key === 'productCost') continue;
        if (typeof obj1[key] === 'object' && obj1[key] !== null) {
            if (!compareObjects(obj1[key], obj2[key])) {
                return false;
            }
        } else if (obj1[key] !== obj2[key]) {
            return false;
        }
    }

    return true;
}

export function hasStateNewOrDeleted(objects: any[]): boolean {
    return objects.some((item) => item.state === DTO_STATE.DTO_NEW || item.state === DTO_STATE.DTO_DELETED);
}

export function getCustomerName(customer: ICustomer): string {
    type CustomerKeys = 'name' | 'faceBook' | 'instagram' | 'email' | 'phoneNumber';

    const content = (['name', 'faceBook', 'instagram', 'email', 'phoneNumber'] as CustomerKeys[])
        .map((key) => customer[key])
        .find((value, index) => (index === 0 ? value.length >= 3 : value.length >= 5));

    return content || '';
}
