import { ICustomerValidator } from './interfaces/ICustomerValidator';
import { ICustomer } from '../models/Customers/ICustomer';
import { matchIsValidTel } from 'mui-tel-input';

export class CustomerValidator implements ICustomerValidator {
    private static instance: CustomerValidator;
    private customer: ICustomer;

    constructor(customer: ICustomer) {
        this.customer = customer;
    }

    private setObjects(customer: ICustomer) {
        this.customer = customer;
    }

    public static getInstance(customer: ICustomer): ICustomerValidator {
        if (!CustomerValidator.instance) {
            CustomerValidator.instance = new CustomerValidator(customer);
        } else {
            CustomerValidator.instance.setObjects(customer);
        }

        return CustomerValidator.instance;
    }

    public isValidFirstName(firstName?: string, validateArg: boolean = false): boolean | undefined {
        const fn = validateArg ? firstName : this.customer.firstName;
        if (!fn) {
            return undefined;
        }

        const exName = new RegExp(`^[\\p{L}\\p{M}\\p{Zs}\\p{Pd}\\p{So}]{3,50}$`, 'iu');

        return exName.test(fn);
    }

    public isValidLastName(lastName?: string, validateArg: boolean = false): boolean | undefined {
        const ln = validateArg ? lastName : this.customer.lastName;
        if (!ln) {
            return undefined;
        }
        const exName = new RegExp(`^[\\p{L}\\p{M}\\p{Zs}\\p{Pd}\\p{So}]{3,50}$`, 'iu');

        return exName.test(ln);
    }

    public isValidEmail(email?: string, validateArg: boolean = false): boolean | undefined {
        const e = validateArg ? email : this.customer.email;
        if (!e) {
            return undefined;
        }
        const exEmail = new RegExp('^[a-z0-9.-]{4,200}@[a-z]{2,20}\\.[a-z]{2,6}$');

        return exEmail.test(e);
    }

    public isValidPhoneNumber(phoneNumber?: string, validateArg: boolean = false): boolean | undefined {
        const phone = validateArg ? phoneNumber : this.customer.phoneNumber;

        if (phone === undefined || phone.length === 0) {
            return undefined;
        }

        return matchIsValidTel(phone);
    }

    public isValidInstagram(instagram?: string, validateArg: boolean = false): boolean | undefined {
        const inst = validateArg ? instagram : this.customer.instagram;
        if (inst === undefined || inst.length === 0) {
            return undefined;
        }

        return inst.length > 5;
    }

    public isValidFacebook(facebook?: string, validateArg: boolean = false): boolean | undefined {
        const fb = validateArg ? facebook : this.customer.faceBook;
        if (fb === undefined || fb.length === 0) {
            return undefined;
        }

        return fb.length > 5;
    }

    public validate(disableConsole?: boolean | undefined): boolean {
        const results: (boolean | undefined)[] = [
            this.isValidFirstName(),
            this.isValidLastName(),
            this.isValidEmail(),
            this.isValidPhoneNumber(),
            this.isValidInstagram(),
            this.isValidFacebook(),
        ];

        const filtered = results.filter((x) => x !== undefined);
        if (filtered.length === 0) {
            return false;
        }

        const isValid: boolean = filtered.every((el) => el);

        if (process.env.NODE_ENV !== 'production' && !disableConsole) {
            const keys: string[] = ['first name', 'last name', 'email', 'phone', 'instagram', 'facebook'];
            for (let i = 0; i < results.length; i++) {
                results[i] !== undefined && console.log(`Is valid ${keys[i]}: ` + results[i]);
            }
            console.log('Is valid customer: ' + isValid);
        }

        return isValid;
    }
}
