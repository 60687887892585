import { ICustomerWithInfos } from '../../../../models/Customers/ICustomerWithInfos';
import { Box, Typography } from '@mui/material';
import { getStyles } from '../../customerCommon';
import { getMedia } from '../../../../common/mediaQuery';

interface Props {
    customerData: ICustomerWithInfos;
}

export const CustomerCard = ({ customerData }: Props) => {
    const { inputStylePadding, updateFormStyle, updateFormInput, buttonWrapper, opacityHalf, progressLoader } = getStyles();
    const { isMobile } = getMedia();

    return (
        <Box sx={{ position: 'relative' }}>
            <Box sx={isMobile ? {} : { display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={updateFormStyle}>
                    {customerData.name && customerData.name.length >= 3 && (
                        <Typography variant="body1" gutterBottom sx={{ display: 'block' }}>
                            <Typography variant="overline">Name:&nbsp;</Typography>
                            {customerData.name}
                        </Typography>
                    )}
                    {customerData.email && (
                        <Typography variant="body1" gutterBottom sx={{ display: 'block' }}>
                            <Typography variant="overline">Email:&nbsp;</Typography>
                            {customerData.email}
                        </Typography>
                    )}
                    {customerData.instagram && (
                        <Typography variant="body1" gutterBottom sx={{ display: 'block' }}>
                            <a href={customerData.instagram.startsWith('http') ? customerData.instagram : `https://${customerData.instagram}`} target="_blank">
                                {customerData.instagram}
                            </a>
                        </Typography>
                    )}
                    {customerData.faceBook && (
                        <Typography variant="body1" gutterBottom sx={{ display: 'block' }}>
                            <Typography variant="overline">FaceBook:&nbsp;</Typography>
                            <a href={customerData.faceBook.startsWith('http') ? customerData.faceBook : `https://${customerData.faceBook}`} target="_blank">
                                {customerData.faceBook}
                            </a>
                        </Typography>
                    )}
                    {customerData.phoneNumber && (
                        <Typography variant="body1" gutterBottom sx={{ display: 'block' }}>
                            <Typography variant="overline">Tel.:&nbsp;</Typography>
                            <a href={`tel:${customerData.phoneNumber}`} style={{ textDecoration: 'none', color: 'blue' }}>
                                {customerData.phoneNumber}
                            </a>
                        </Typography>
                    )}
                </Box>
                <Box sx={updateFormStyle}>
                    <Typography variant="body1" gutterBottom sx={{ display: 'block' }}>
                        <Typography variant="overline">Orders:&nbsp;</Typography>
                        {customerData.totalOrderCount}
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ display: 'block' }}>
                        <Typography variant="overline">Rejected:&nbsp;</Typography>
                        {customerData.closedOrderCount}
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ display: 'block' }}>
                        <Typography variant="overline">Planed:&nbsp;</Typography>
                        {customerData.openOrderCount}
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ display: 'block' }}>
                        <Typography variant="overline">Avr. price:&nbsp;</Typography>
                        {customerData.averageOrderPrice}
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ display: 'block' }}>
                        <Typography variant="overline">Avr. profit:&nbsp;</Typography>
                        {customerData.averageOrderProfit}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};
