import { useEffect, useState } from 'react';
import { AlertColor, Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InfoIcon from '@mui/icons-material/Info';
import Link from '@mui/material/Link';

import { getCommonStyles } from '../../../common/commonStyles';
import { getDateTime } from '../../../common/helpers';
import { getMedia } from '../../../common/mediaQuery';
import { ICloseOrder } from '../../../models/Order/ICloseOrder';
import { ICustomer } from '../../../models/Customers/ICustomer';
import { IOrder } from '../../../models/Order/IOrder';
import { FIELDS, ORDER_STAGES } from '../../../common/dictionary';
import { orderAPI } from '../../../services/OrderServices';
import { useLanguage } from '../../../context/LanguageContext';
import getTitle from '../../../common/translations';
import MySnackBar from '../../common/snackBars/MySnackBar';
import SubOrder from './SubOrder';
import { fetchImage } from '../../../services/ImageService';
import { IOrderImage } from '../../../models/Order/IOrderImage';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface OrderProps {
    order: IOrder;
    handleOrderId: (orderId: number) => void;
    handleEditMode: () => void;
}

interface Image extends IOrderImage {
    recipeName: string;
    url: string;
}
const successColor: AlertColor = 'success';
const errorColor: AlertColor = 'error';

const Order = ({ order, handleOrderId, handleEditMode }: OrderProps) => {
    const { language } = useLanguage();
    const { isMobile, isTablet } = getMedia();
    const { mainBtnStyle, simpleBtnStyle } = getCommonStyles();
    const [initRender, setInitRender] = useState(true);
    const [images, setImages] = useState<Image[]>();

    const backgroundStyle =
        order.stage === ORDER_STAGES.PREPAID
            ? '#f5c7d250'
            : order.stage === ORDER_STAGES.NEW
              ? '#fef6f950'
              : order.stage === ORDER_STAGES.CANCELED
                ? 'red'
                : order.stage === ORDER_STAGES.DONE
                  ? '#d6f2b550'
                  : order.stage === ORDER_STAGES.PICKED_UP
                    ? '#ffc59450'
                    : '#fff';
    const [moreInfo, setMoreInfo] = useState(false);
    const [changeOrderStage, { error: changeOrderStageError, isSuccess: changeOrderStageIsSuccess }] = orderAPI.useChangeOrderStageMutation();
    const [closeOrder, { error: closeOrderError, isSuccess: closeOrderIsSuccess }] = orderAPI.useCloseOrderMutation();
    const [deleteOrder, { error: deleteOrderError, isSuccess: deleteOrderIsSuccess }] = orderAPI.useDeleteOrderMutation();
    const [openSnackBar, setSnackBarOpen] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState('');
    const buttonStyle = { lineHeight: '1.25rem' /* fontSize: '0.75rem' */ };
    const forPayment = order.price - order.prepayment!.prepayment;
    const [currentIndex, setCurrentIndex] = useState(0);
    const [openImages, setOpenImages] = useState(false);
    const [imageSize, setImageSize] = useState({ width: '100%', height: 'auto' });

    const [fadeProp, setFadeProp] = useState({
        fade: 'fade-in',
    });

    const styles = {
        dialogContent: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            overflow: 'hidden',
            transition: 'height 0.5s ease',
            maxHeight: '80vh',
        },
        carouselImage: {
            ...imageSize,
            maxHeight: isMobile || isTablet ? '50vh' : '70vh',
            objectFit: 'contain',
            transition: 'width 0.5s ease, height 0.5s ease, opacity 0.5s ease-in-out',
            opacity: fadeProp.fade === 'fade-in' ? 1 : 0,
        },
    };

    const handleNext = () => {
        setFadeProp({ fade: 'fade-out' });
        if (images) {
            setTimeout(() => {
                setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
            }, 500);
        }
    };

    const handlePrev = () => {
        setFadeProp({ fade: 'fade-out' });
        if (images) {
            setTimeout(() => {
                setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
            }, 500);
        }
    };

    const handleOpenImages = () => {
        setOpenImages(!openImages);
    };

    const handleClose = (e?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBarOpen(false);
    };
    const handleId = () => {
        handleOrderId(Number(order.id));
    };

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [deleteOrderOpen, setDeleteOrderOpen] = useState<boolean>(false);
    const [closeOrderOpen, setCloseOrderOpen] = useState<boolean>(false);
    const handelDeleteOrder = () => {
        deleteOrder(order.id as number);
    };
    const datePickUp = getDateTime(order.pickUpAt);
    const handleChangeOrderStage = (id: number) => {
        changeOrderStage(id);
    };

    const handleCloseOrder = (e: any, isReceiptIssued: boolean) => {
        const dto: ICloseOrder = { id: order.id as number, isReceiptIssued: isReceiptIssued };
        closeOrder(dto).then((res: any) => {
            if ('data' in res) setCloseOrderOpen(!closeOrderOpen);
        });
    };

    const isReceiptIssued = order.stage === ORDER_STAGES.DONE || (order.stage === ORDER_STAGES.PICKED_UP && order.isReceiptIssued === false);
    const disableDoneButton = order.stage !== ORDER_STAGES.DONE && order.stage !== ORDER_STAGES.PICKED_UP;

    // FIXME: Only for Natali. Later check userID or user name and show copy button for Natali
    const createOrderLabel = () => {
        window.navigator.clipboard.writeText(`${datePickUp?.date}\r${order.customer?.name}\r${order.description}`);
    };

    useEffect(() => {
        if (changeOrderStageIsSuccess && !changeOrderStageError) setSnackBarOpen(true);
        if (!changeOrderStageIsSuccess && changeOrderStageError) {
            if ('data' in changeOrderStageError) setErrorMessage(changeOrderStageError.data as string);
            setSnackBarOpen(true);
        }
    }, [changeOrderStageIsSuccess, changeOrderStageError, errorMessage]);

    useEffect(() => {
        if (!initRender && order.subOrders) {
            const loadImage = async () => {
                for (const subOrder of order.subOrders || []) {
                    for (const image of subOrder.images || []) {
                        const { imageUrl, error } = await fetchImage(image.fileName);
                        setImages((prevImages) => [...(prevImages || []), { ...image, recipeName: subOrder.recipeName, url: imageUrl }]);
                    }
                }
            };

            loadImage();
        }

        if (initRender) {
            setInitRender(false);
        }
    }, [initRender]);

    useEffect(() => {
        setFadeProp({ fade: 'fade-in' });
        const img = new Image();
        img.src = images ? images[currentIndex]?.url : '';
        img.onload = () => {
            setImageSize({
                width: img.width > 800 ? '800px' : `${img.width}px`,
                height: `${img.height}px`,
            });
        };
    }, [currentIndex, images]);

    return (
        <Box sx={{ padding: '10px', backgroundColor: backgroundStyle, display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', boxShadow: '0px 1px 3px #999' }}>
            <Box sx={{ width: isMobile ? '100%' : '60%' }}>
                <Box sx={{ display: 'flex', mt: '5px', mb: '5px' }}>
                    <Box
                        sx={{
                            backgroundColor: '#d5f7de',
                            width: 'fit-content',
                            padding: '5px',
                            borderRadius: '5px',
                            mr: '10px',
                        }}
                    >
                        {getTitle(language, FIELDS.ORDERS.PICK_UP_DATE)}
                        {datePickUp?.date}
                    </Box>
                    <Box sx={{ backgroundColor: '#c5ecfc', width: 'fit-content', padding: '5px', borderRadius: '5px' }}>
                        {getTitle(language, FIELDS.ORDERS.PICK_UP_TIME)}
                        {datePickUp?.time}
                    </Box>
                    {/* Only for Natali. Later check userID or user name and show copy button for Natali */}
                    <Box>
                        <Button
                            variant="outlined"
                            sx={{
                                ...buttonStyle,
                                ...mainBtnStyle,
                                ...(isMobile && { width: 'auto', minWidth: '50px' }),
                            }}
                            onClick={createOrderLabel}
                        >
                            <PrintOutlinedIcon />
                        </Button>
                    </Box>
                </Box>

                <Box sx={{ mt: '5px', display: 'flex', position: 'relative' }}>
                    <Box sx={{ margin: 'auto 0', pr: '20px', borderBottom: '1px solid rgba(25, 118, 210, 0.88)', marginBottom: '2px' }}>
                        {getTitle(language, FIELDS.ORDERS.CUSTOMER)} {order.customer?.name}
                    </Box>

                    <Tooltip title={getTitle(language, FIELDS.ORDERS.CUSTOMER_INFO)}>
                        <Button variant="text" onClick={() => setDialogOpen(!dialogOpen)} sx={{ minWidth: '32px', padding: '0px', ml: '-15px' }}>
                            <InfoIcon sx={{ color: 'rgba(25, 118, 210, 0.88)' }} />
                        </Button>
                    </Tooltip>
                </Box>

                <Box>
                    {getTitle(language, FIELDS.ORDERS.ORDER_NUMBER)}
                    {order.orderNumber}
                </Box>

                {order.description && (
                    <Box>
                        {getTitle(language, FIELDS.ORDERS.DESCRIPTION_ORDERS)}
                        <Typography sx={{ whiteSpace: 'pre-line', fontWeight: 600, fontSize: '100%' }}>{order.description}</Typography>
                    </Box>
                )}

                {isTablet ? (
                    <Box sx={{ mt: '5px' }}>
                        {order.customer!.faceBook && (
                            <Box>
                                <a href={order.customer!.faceBook} target="_blank">
                                    {getTitle(language, FIELDS.ORDERS.FACEBOOK).replace(':', '')}
                                </a>
                            </Box>
                        )}
                        {order.customer!.instagram && (
                            <Box>
                                <a href={order.customer!.instagram} target="_blank">
                                    {getTitle(language, FIELDS.ORDERS.INSTAGRAM).replace(':', '')}
                                </a>
                            </Box>
                        )}
                    </Box>
                ) : (
                    <></>
                )}

                <Box sx={{ mt: '5px', mb: '5px' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Button
                            variant="outlined"
                            onClick={() => setMoreInfo(!moreInfo)}
                            sx={{
                                ...buttonStyle,
                                fontWeight: '600',
                                boxShadow: '0px 1px 1px #999',
                                backgroundColor: 'rgba(25, 118, 210, 0.08)',
                            }}
                        >
                            {getTitle(language, FIELDS.ORDERS.MORE_INFO)}
                        </Button>

                        {isTablet ? (
                            <></>
                        ) : (
                            <Box sx={{ margin: 'auto 0', display: 'flex' }}>
                                {order.customer!.faceBook && (
                                    <Box
                                        sx={{
                                            ml: '15px',
                                            mr: '15px',
                                        }}
                                    >
                                        <Link href={order.customer!.faceBook} target="_blank" rel="noopener">
                                            {getTitle(language, FIELDS.ORDERS.FACEBOOK).replace(':', '')}
                                        </Link>
                                    </Box>
                                )}
                                {order.customer!.instagram && (
                                    <Box>
                                        <Link href={order.customer!.instagram} target="_blank" rel="noopener">
                                            {getTitle(language, FIELDS.ORDERS.INSTAGRAM).replace(':', '')}
                                        </Link>
                                    </Box>
                                )}
                            </Box>
                        )}
                    </Box>

                    <Collapse in={moreInfo} timeout="auto" unmountOnExit>
                        <Box style={{ margin: 10 }}>
                            {order.subOrders?.map((subOrder) => (
                                <SubOrder key={subOrder.id} subOrder={subOrder} imageUrl={images?.filter((el) => el.subOrderId === subOrder.id) ?? []} />
                            ))}
                        </Box>
                    </Collapse>
                </Box>

                {images && (
                    <>
                        <Button onClick={handleOpenImages}>images</Button>
                        <Dialog open={openImages} onClose={handleOpenImages} maxWidth="md" fullWidth>
                            <DialogTitle>
                                {images[currentIndex]?.recipeName} {images[currentIndex]?.isResultImage ? 'Результат' : 'Целевое изображение'}
                                <IconButton onClick={handleOpenImages} sx={{ position: 'absolute', right: 8, top: 8 }}>
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent sx={styles.dialogContent}>
                                {currentIndex > 0 && (
                                    <IconButton onClick={handlePrev} sx={{ position: 'absolute', left: 16, zIndex: 1 }}>
                                        <ArrowBackIosIcon />
                                    </IconButton>
                                )}
                                <Box component="img" src={images[currentIndex]?.url || ''} alt={`Изображение ${currentIndex + 1}`} sx={styles.carouselImage} />
                                {currentIndex < images.length - 1 && (
                                    <IconButton onClick={handleNext} sx={{ position: 'absolute', right: 16, zIndex: 1 }}>
                                        <ArrowForwardIosIcon />
                                    </IconButton>
                                )}
                            </DialogContent>
                        </Dialog>
                    </>
                )}
                <Box>
                    {getTitle(language, FIELDS.ORDERS.PRICE_ORDERS)}
                    {order.price} zł
                </Box>
                <Box>
                    {getTitle(language, FIELDS.ORDERS.ORDER_COST)}
                    {order.cost} zł
                </Box>
                <Box>
                    {getTitle(language, FIELDS.ORDERS.PREPAYMENT)}
                    {order.stage === ORDER_STAGES.PREPAID
                        ? `${order.prepayment!.prepayment} zł ${getDateTime(order.prepayment?.prepaymentAt)?.date || order.prepayment?.prepaymentAt}`
                        : `${getTitle(language, FIELDS.ORDERS.WAITING)}`}
                </Box>
                <Box>
                    {getTitle(language, FIELDS.ORDERS.REST_FOR_PAYMENT)}
                    {forPayment} zł
                </Box>
            </Box>

            <Box sx={{ minWidth: isMobile ? '100%' : '40%', alignContent: 'center' }}>
                <Stack justifyContent="space-between" alignItems="center" sx={{ ...(isMobile && { display: 'flex', flexDirection: 'row', mt: '10px' }) }}>
                    <Button
                        disabled={false}
                        variant="outlined"
                        onClick={handleId}
                        sx={{
                            ...buttonStyle,
                            ...mainBtnStyle,
                            ...(isMobile && { width: 'auto', minWidth: '50px' }),
                        }}
                    >
                        {isMobile ? '' : getTitle(language, FIELDS.ORDERS.EDIT)}
                        &nbsp;
                        <DriveFileRenameOutlineIcon />
                    </Button>
                    <Button
                        disabled={!disableDoneButton}
                        variant="outlined"
                        onClick={() => handleChangeOrderStage(Number(order.id))}
                        sx={{
                            ...buttonStyle,
                            ...mainBtnStyle,
                            ...(isMobile && { width: 'auto', minWidth: '50px' }),
                        }}
                    >
                        {isMobile ? '' : getTitle(language, FIELDS.ORDERS.ORDER_DONE)}
                        &nbsp;
                        <CheckCircleOutlineIcon />
                    </Button>
                    <Button
                        disabled={!isReceiptIssued}
                        variant="outlined"
                        onClick={() => setCloseOrderOpen(!closeOrderOpen)}
                        sx={{
                            ...buttonStyle,
                            ...mainBtnStyle,
                            ...(isMobile && { width: 'auto', minWidth: '50px' }),
                        }}
                    >
                        {isMobile ? '' : getTitle(language, FIELDS.ORDERS.PICKED_UP)}
                        &nbsp;
                        <HighlightOffIcon />
                    </Button>
                    <Button
                        disabled={!disableDoneButton}
                        variant="outlined"
                        onClick={() => setDeleteOrderOpen(!deleteOrderOpen)}
                        sx={{
                            ...buttonStyle,
                            ...mainBtnStyle,
                            ...(isMobile && { width: 'auto', minWidth: '50px' }),
                        }}
                    >
                        {isMobile ? '' : getTitle(language, FIELDS.ORDERS.DELETE_ORDER)}
                        &nbsp;
                        <DeleteOutlineIcon />
                    </Button>
                </Stack>
            </Box>

            <Dialog open={deleteOrderOpen} onClose={() => setDeleteOrderOpen(!deleteOrderOpen)}>
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Button variant="outlined" onClick={() => setDeleteOrderOpen(!deleteOrderOpen)} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                            {getTitle(language, FIELDS.ORDERS.CLOSE)}
                        </Button>
                    </Box>
                </DialogTitle>
                <DialogContent sx={{ width: '250px', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box>{getTitle(language, FIELDS.ORDERS.DELETE_ORDER_MESSAGE)}</Box>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-around', mb: '10px' }}>
                    <Button variant="outlined" onClick={handelDeleteOrder} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                        {getTitle(language, FIELDS.COMMON.YES)}
                    </Button>
                    <Button variant="outlined" onClick={() => setDeleteOrderOpen(!deleteOrderOpen)} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                        {getTitle(language, FIELDS.COMMON.NO)}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={closeOrderOpen} onClose={() => setCloseOrderOpen(!closeOrderOpen)}>
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Button variant="outlined" onClick={() => setCloseOrderOpen(!closeOrderOpen)} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                            {getTitle(language, FIELDS.ORDERS.CLOSE)}
                        </Button>
                    </Box>
                </DialogTitle>
                <DialogContent sx={{ width: '250px', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box>{getTitle(language, FIELDS.ORDERS.RECEIPT_ISSUED)}</Box>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-around', mb: '10px' }}>
                    <Button variant="outlined" onClick={(e) => handleCloseOrder(e, true)} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                        {getTitle(language, FIELDS.COMMON.YES)}
                    </Button>
                    <Button variant="outlined" onClick={(e) => handleCloseOrder(e, false)} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                        {getTitle(language, FIELDS.COMMON.NO)}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog fullWidth open={dialogOpen} onClose={() => setDialogOpen(!dialogOpen)}>
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box>{getTitle(language, FIELDS.ORDERS.CUSTOMER_INFO)}</Box>
                        <Box>
                            <Button variant="outlined" onClick={() => setDialogOpen(!dialogOpen)} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                                {getTitle(language, FIELDS.ORDERS.CLOSE)}
                            </Button>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>{order.customer && <CustomerInDialog key={order.customer.id} customer={order.customer} />}</DialogContent>
                <DialogActions></DialogActions>
            </Dialog>

            {changeOrderStageIsSuccess && <MySnackBar duration={5000} openSnackBar={openSnackBar} handleClose={handleClose} severity={successColor} alertText="Success" />}
            {!changeOrderStageIsSuccess && changeOrderStageError && (
                <MySnackBar duration={5000} openSnackBar={openSnackBar} handleClose={handleClose} severity={errorColor} alertText={errorMessage} />
            )}
        </Box>
    );
};

export default Order;

interface CustomerInDialogProps {
    customer: ICustomer;
}

const CustomerInDialog = ({ customer }: CustomerInDialogProps) => {
    const { language } = useLanguage();

    return (
        <Box style={{ backgroundColor: 'beige' }}>
            <Box component={'p'}>
                {getTitle(language, FIELDS.ORDERS.FULL_NAME)}
                {customer.name}
            </Box>
            <Box component={'p'}>
                {getTitle(language, FIELDS.ORDERS.EMAIL)}
                {customer.email}
            </Box>
            <Box component={'p'}>
                {getTitle(language, FIELDS.ORDERS.PHONE_NUMBER)}
                {customer.phoneNumber}
            </Box>
            <Box component={'p'}>
                {getTitle(language, FIELDS.ORDERS.INSTAGRAM)}
                {customer.instagram}
            </Box>
            <Box component={'p'}>
                {getTitle(language, FIELDS.ORDERS.FACEBOOK)}
                {customer.faceBook}
            </Box>
        </Box>
    );
};
