import { SetStateAction, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { EnhancedTableToolbar } from './EnhancedTableToolbar';
import { EnhancedTableHead } from './EnhancedTableHead';
import { IRecipe, ServiceSize } from '../../../models/Recipe/IRecipe';
import { getStyles, Order } from '../recipeCommon';
import { CLEAR_BUTTON } from '../../../common/dictionary';
import getTitle from '../../../common/translations';
import { useLanguage } from '../../../context/LanguageContext';
import { getRecipeSize } from '../../../common/helpers';
import { RecipeHOC } from '../recipe/RecipeHOC';

type Props = {
    items: IRecipe[];
    itemsQtyTotal: number;
    itemsPerPage: number;
    currentPageNumber: number;
    setItemsPerPage: (qty: number) => void;
    setCurrentPageNumber: (page: number) => void;
    isNewDataFetching: boolean;
    setIsNewDataFetching: (arg: boolean) => void;
    setSearchPhrase: (arg: string) => void;
    setSearchInput: (arg: string) => void;
    searchInput: string;
    setOrderByName: () => void;
    orderByName: boolean;
    setIsAllRecipesIsLoading: (arg: boolean) => void;
};

export function EnhancedCollapsibleTable({
    items,
    currentPageNumber,
    itemsQtyTotal,
    itemsPerPage,
    setItemsPerPage,
    setCurrentPageNumber,
    isNewDataFetching,
    setIsNewDataFetching,
    setSearchPhrase,
    setSearchInput,
    searchInput,
    setOrderByName,
    orderByName,
    setIsAllRecipesIsLoading,
}: Props) {
    const {
        useGetHeaders,
        rowsPerPageOptions,
        debounceTimeValue,
        tableWrapper,
        tablePaperWrapper,
        tableWidthStyle,
        tablePaginationStyle,
        opacityHalf,
        progressLoader,
        tablePaginationSelectProps,
        tablePagination,
    } = getStyles();
    const headCells = useGetHeaders();
    const { language } = useLanguage();

    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<string>(orderByName ? 'name' : '');
    const [rowOpen, setRowOpen] = useState<Record<number, boolean>>({});
    const [editOpen, setEditOpen] = useState<Record<number, boolean>>({});
    const [serviceSize, setServiceSize] = useState<ServiceSize>({ '-1': { partOfWeight: '1.00' } });
    const [isLoading, setIsLoading] = useState(false);
    const [debouncedValue, setDebouncedValue] = useState(searchInput);

    const emptyRows = currentPageNumber > 0 ? Math.max(0, (1 + currentPageNumber) * itemsPerPage - items.length) : 0;

    // methods
    const handleClickCollapse = (id: string | number) => {
        const isOpen = !!rowOpen[Number(id)];
        const isEdit = !!editOpen[Number(id)];

        setRowOpen((prevRowOpen) => ({
            ...prevRowOpen,
            [id]: !isOpen,
        }));

        if (isEdit) {
            setEditOpen((prevRowOpen) => ({
                ...prevRowOpen,
                [id]: !isEdit,
            }));
        }

        setServiceSize((prevServiceSize) => {
            if (!isOpen) {
                delete prevServiceSize[Number(id)];
            }

            return prevServiceSize;
        });
    };

    const handleClickEdit = async (id: string | number) => {
        const isEdit = !!editOpen[Number(id)];

        if (!isEdit) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }

        setEditOpen((prevRowOpen) => ({
            ...prevRowOpen,
            [id]: !isEdit,
        }));
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: SetStateAction<string>) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);

        setOrderByName();
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        if (newPage >= 0 && newPage < Math.ceil(itemsQtyTotal / itemsPerPage) && newPage > currentPageNumber) {
            setCurrentPageNumber(newPage);
        } else {
            setCurrentPageNumber(newPage);
        }
        setIsNewDataFetching(true);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItemsPerPage(parseInt(event.target.value, 10));
        setCurrentPageNumber(0);
    };

    const handleChangeSearch = (value: string) => {
        if (value === CLEAR_BUTTON) {
            setSearchInput('');
            setSearchPhrase('');
        } else {
            setSearchInput(value);
        }
    };

    const handleUnitOfWeight = (partValue: string, id: string | number) => {
        setServiceSize((prevServiceSize) => {
            const updatedServiceSize = {
                ...prevServiceSize,
                [id]: {
                    partOfWeight: partValue,
                },
            };
            return updatedServiceSize;
        });
    };

    useEffect(() => {
        const t = setTimeout(() => {
            setDebouncedValue(searchInput);
        }, debounceTimeValue);

        return () => {
            clearTimeout(t);
        };
    }, [searchInput]);

    useEffect(() => {
        setSearchPhrase(debouncedValue);
    }, [debouncedValue]);

    return (
        <Box sx={tableWrapper}>
            {isNewDataFetching ? (
                <Box sx={progressLoader}>
                    <CircularProgress color="success" />
                </Box>
            ) : (
                <></>
            )}
            <Paper
                sx={{
                    ...tablePaperWrapper,
                    ...tablePaginationStyle,
                    ...(isNewDataFetching ? { opacityHalf } : {}),
                }}
            >
                <EnhancedTableToolbar searchInput={searchInput} handleChangeSearch={handleChangeSearch} setIsAllRecipesIsLoading={setIsAllRecipesIsLoading} items={items} />

                <TableContainer>
                    <Table sx={tableWidthStyle} aria-labelledby="tableTitle">
                        <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={items?.length || 0} visibleColumns={headCells} />
                        <TableBody>
                            {items.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={6}>{getTitle(language, 'no_records')}</TableCell>
                                </TableRow>
                            )}
                            {items.map((row: IRecipe, index: number) => (
                                <RecipeHOC
                                    key={`${index}_${row.createdAt}`}
                                    row={row}
                                    index={index}
                                    serviceSize={serviceSize}
                                    getRecipeSize={getRecipeSize}
                                    handleUnitOfWeight={handleUnitOfWeight}
                                    editOpen={editOpen}
                                    handleClickCollapse={handleClickCollapse}
                                    rowOpen={rowOpen}
                                    handleClickEdit={handleClickEdit}
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                />
                            ))}

                            {(emptyRows > 0 && items?.length === 0) ||
                                (items?.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={itemsQtyTotal}
                    rowsPerPage={itemsPerPage}
                    page={currentPageNumber}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    SelectProps={tablePaginationSelectProps}
                    sx={tablePagination}
                />
            </Paper>
        </Box>
    );
}

/* 
    TODO: when search check pagination behavior
    - now if total 5 pages and user stays on 4th but after search got only 3 pages error comes in console
    - Failed prop type: MUI: The page prop of a TablePagination is out of range (0 to 1, but page is 2).
 */
