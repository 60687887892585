import { SetStateAction } from 'react';
import { Box, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useLanguage } from '../../../context/LanguageContext';
import getTitle from '../../../common/translations';
import { getStyles, HeadCell } from '../customerCommon';
import { Order } from '../../items/tableCommon';

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: SetStateAction<string>) => void;
    order: Order;
    orderBy: string;
    visibleColumns: HeadCell[];
}

export function EnhancedTableHead({ order, orderBy, onRequestSort, visibleColumns }: EnhancedTableProps) {
    const { language } = useLanguage();
    const { tableRowStyle, tableCellStyle } = getStyles();

    const createSortHandler = (property: SetStateAction<string>) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead sx={tableRowStyle}>
            <TableRow>
                {visibleColumns.map((headCell, index) => {
                    return (
                        <TableCell
                            key={headCell.id}
                            align={index === 0 ? 'left' : 'center'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                            sx={tableCellStyle}
                        >
                            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
                                {getTitle(language, headCell.label)}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}
