import { DELETE_CUSTOMER, GET_ALL_CUSTOMERS_WITH_INFOS, GET_CUSTOMERS, SAVE_CUSTOMER, SEARCH_CUSTOMERS, UPDATE_CUSTOMER } from '../common/endpoints';
import { ICustomer } from '../models/Customers/ICustomer';
import { ISearchString } from '../models/ISearchString';
import { baseAPI } from './BaseAPI';
import { ICustomerWithInfos } from '../models/Customers/ICustomerWithInfos';

type SearchPropsType = {
    page: number;
    limit: number;
    searchPhrase: string;
    descending: boolean;
    orderByName: boolean;
    orderByOrdersQty: boolean;
};

type SearchResponseType = {
    data: ICustomerWithInfos[];
    page: number;
    pageCount: number;
    pageSize: number;
    totalCount: number;
};

export const customerAPI = baseAPI.injectEndpoints({
    endpoints: (build) => ({
        /*getAllCustomers: build.query<ICustomer[], void>({
            query: () => ({
                url: GET_ALL_CUSTOMERS,
                method: 'GET',
            }),

            providesTags: (result) => ['Customer'],
        }),*/

        getAllCustomers: build.query<ICustomerWithInfos[], void>({
            query: () => ({
                url: GET_ALL_CUSTOMERS_WITH_INFOS,
                method: 'GET',
            }),

            providesTags: (result) => ['Customers'],
        }),

        getCustomers: build.query<SearchResponseType, SearchPropsType>({
            query: ({ page, limit, searchPhrase, descending, orderByName, orderByOrdersQty }: SearchPropsType) => ({
                url: `${GET_CUSTOMERS}?page=${page}&limit=${limit}&searchPhrase=${searchPhrase}&descending=${descending}&orderByName=${orderByName}&orderByOrdersCount=${orderByOrdersQty}`,
                method: 'GET',
            }),

            providesTags: (result) => ['Customers'],
        }),

        searchCustomers: build.mutation<ICustomer[], ISearchString>({
            query: (searchPhrase: ISearchString) => ({
                url: SEARCH_CUSTOMERS,
                method: 'POST',
                body: searchPhrase,
            }),

            invalidatesTags: (result) => ['Customers'],
        }),

        // TODO: use save customer endpoint for update customer. Only we need to invalidate customer with id and create getCustomerById endpoint for refresh only updated customer
        //       when delete customer invalidate whole customer list
        updateCustomer: build.mutation<ICustomer[], ICustomer>({
            query: (data: ICustomer) => ({
                url: UPDATE_CUSTOMER,
                method: 'POST',
                body: {
                    id: data.id,
                    name: `${data.firstName} ${data.lastName}`,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    phoneNumber: data.phoneNumber,
                    instagram: data.instagram,
                    faceBook: data.faceBook,
                    isDeleted: data.isDeleted,
                    createdAt: data.createdAt,
                    state: data.state,
                },
            }),

            invalidatesTags: (result) => ['Customers'],
        }),

        deleteCustomer: build.mutation<ICustomer, number | undefined>({
            query: (id: number) => ({
                url: `${DELETE_CUSTOMER}/${id}`,
                method: 'DELETE',
            }),

            invalidatesTags: ['Customers'],
        }),

        saveCustomer: build.mutation<ICustomer, Partial<ICustomer>>({
            query: (customer: Partial<ICustomer>) => ({
                url: SAVE_CUSTOMER,
                method: 'POST',
                body: customer,
            }),

            invalidatesTags: (result) => ['Customers'],
        }),
    }),
});
