import { Fragment, SetStateAction, useCallback, useId, useState } from 'react';
import { IRecipe, ServiceSize } from '../../../models/Recipe/IRecipe';
import { Box, Button, Collapse, FormControl, IconButton, InputLabel, MenuItem, Select, TableCell, TableRow } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getStyles } from '../recipeCommon';
import { EditRecipeHOC } from './EditRecipe/EditRecipeHOC';
import Recipe from './Recipe';
import priceFormatter from '../../../common/priceFormatter';
import { getMedia } from '../../../common/mediaQuery';
import getTitle from '../../../common/translations';
import { useLanguage } from '../../../context/LanguageContext';
import { IFilling } from '../../../models/Recipe/Filling/IFilling';
import { DeleteForm } from './DeleteForm';

interface Props {
    row: IRecipe;
    index: number;
    serviceSize: ServiceSize;
    getRecipeSize: (recipe: IRecipe) => string[];
    handleUnitOfWeight: (partValue: string, id: string | number) => void;
    editOpen: Record<number, boolean>;
    handleClickCollapse: (id: string | number) => void;
    rowOpen: Record<number, boolean>;
    handleClickEdit: (id: string | number) => Promise<void>;
    isLoading: boolean;
    setIsLoading: React.Dispatch<SetStateAction<boolean>>;
}

export const RecipeHOC = ({
    row,
    index,
    serviceSize,
    getRecipeSize,
    handleUnitOfWeight,
    editOpen,
    handleClickCollapse,
    rowOpen,
    handleClickEdit,
    isLoading,
    setIsLoading,
}: Props) => {
    const {
        tableRowWrapper,
        tableRowStyle,
        tableModalsWrapper,
        tableBodyBox,
        inputTableBodyLabel,
        tableBodySecondBox,
        fontSize,
        controlsWrapper,
        controlsBoxWrapper,
        controlsButtons,
        infoEditBox,
        recipeBoxWrapper,
    } = getStyles();
    const { isTablet } = getMedia();
    const { language } = useLanguage();
    const uniqueId = useId();

    const proportions: string[] = row.proportionInfos.map((pi) => pi.proportion);
    const labelId = `enhanced-table-checkbox-${index}`;
    const size = serviceSize[Number(row.id)]?.partOfWeight || (row.isLinear ? '1.00' : (proportions && Number(proportions[0]).toFixed(2)) || '1.00');
    const size_options = getRecipeSize(row);
    const [selectedFilling, setSelectedFilling] = useState<IFilling | undefined>(undefined);

    const [price, setPrice] = useState(row.proportionInfos.length > 0 ? priceFormatter((Number(row.proportionInfos[0].cost || '0.00') * Number(size)).toFixed(2)) : '0.00');
    const handlePriceChange = useCallback((filling: IFilling | undefined, fillingPrice: string) => {
        setSelectedFilling(filling);
        setPrice(priceFormatter(((Number(price) + Number(fillingPrice)) * Number(size)).toFixed(2)));
    }, []);

    const collapseControls = (
        <Box sx={{ display: 'flex', flexDirection: isTablet ? 'column' : 'row' }}>
            <Box sx={{ ...tableBodyBox }}>
                <InputLabel htmlFor="partsWeight" sx={isTablet ? inputTableBodyLabel : {}} onClick={(e) => e.preventDefault()}>
                    {getTitle(language, 'service_size')}
                </InputLabel>

                <FormControl variant="standard">
                    <Select
                        labelId="partsWeight-label"
                        id="partsWeight"
                        value={size}
                        onChange={(e) => handleUnitOfWeight(e.target.value, row.id)}
                        disableUnderline
                        disabled={editOpen[Number(row.id)]}
                        sx={{
                            '& .MuiSelect-select': {
                                pr: '25px !important',
                                pl: '5px !important',
                                ml: '10px',
                                ':hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.025)',
                                },
                                ':focus': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.025)',
                                },
                            },
                            '& .MuiInputBase-input': {
                                ...(isTablet ? { ...fontSize } : {}),
                                padding: '0px',
                            },
                        }}
                    >
                        {size_options.map((option: any) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <Box sx={{ ...tableBodySecondBox, ...(isTablet ? { ml: '0px', mb: '10px', mt: '5px' } : {}) }}>
                <InputLabel htmlFor="partsWeight" sx={isTablet ? { fontSize } : {}}>
                    {getTitle(language, 'service_price')}:&nbsp;
                </InputLabel>
                <Box
                    sx={{
                        ...(isTablet ? { fontSize } : {}),
                    }}
                >
                    {price} &nbsp;zł
                </Box>
            </Box>
        </Box>
    );

    return (
        <Fragment key={uniqueId + index}>
            <TableRow
                hover
                onClick={(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
                    event.stopPropagation();
                    handleClickCollapse(row.id);
                }}
                role="checkbox"
                tabIndex={-1}
                key={row.id}
                sx={{ ...tableRowWrapper, ...(index % 2 && tableRowStyle) }}
            >
                <TableCell id={labelId} padding="none">
                    <IconButton aria-label="expand row" size="small">
                        {rowOpen[Number(row.id)] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    {row.recipeName}
                </TableCell>

                <TableCell align="center">{price} &nbsp;zł</TableCell>
            </TableRow>

            {/* item manager in collapse section under the row */}
            <TableRow>
                <TableCell style={tableModalsWrapper} colSpan={6}>
                    <Collapse in={!!rowOpen[Number(row.id)]} timeout="auto" unmountOnExit>
                        {/* controls */}
                        <Box sx={controlsWrapper}>
                            <Box sx={controlsBoxWrapper}>
                                <Button
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleClickEdit(row.id);
                                    }}
                                    sx={{ ...controlsButtons, ...(isTablet ? { width: 'auto', pl: '5px', pr: '5px' } : {}) }}
                                >
                                    {!!editOpen[Number(row.id)] ? getTitle(language, 'cancel_editing') : getTitle(language, 'edit_recipe')}
                                </Button>

                                {/* info */}
                                {isTablet ? <></> : <>{collapseControls}</>}
                            </Box>
                        </Box>

                        {/* info */}
                        {isTablet && !!!editOpen[Number(row.id)] ? <Box sx={infoEditBox}>{collapseControls}</Box> : <></>}

                        {/* recipe data */}
                        <Box>
                            <Box sx={recipeBoxWrapper}>
                                {/* for tablet and mobile */}
                                {!editOpen[Number(row.id)] && isTablet && (
                                    <Recipe
                                        key={Number(row.id) + Math.random()}
                                        recipe={row}
                                        openedRow={!!editOpen[Number(row.id)]}
                                        isLoading={isLoading}
                                        size={Number(size)}
                                        setFillingPrice={handlePriceChange}
                                        selectedFilling={selectedFilling}
                                        editOpen={editOpen[Number(row.id)]}
                                    />
                                )}

                                {/* for desktop */}
                                {!isTablet && (
                                    <Recipe
                                        key={Number(row.id) + Math.random()}
                                        recipe={row}
                                        openedRow={!!editOpen[Number(row.id)]}
                                        isLoading={isLoading}
                                        size={Number(size)}
                                        setFillingPrice={handlePriceChange}
                                        selectedFilling={selectedFilling}
                                        editOpen={editOpen[Number(row.id)]}
                                    />
                                )}

                                {/* for desktop, tablet and mobile for linear recipe */}
                                {editOpen[Number(row.id)] && (
                                    <EditRecipeHOC
                                        key={`${row.id}-${row.recipeName || row.createdAt}`}
                                        recipe={row}
                                        setIsLoading={setIsLoading}
                                        isLoading={isLoading}
                                        handleClickEdit={handleClickEdit}
                                    />
                                )}
                            </Box>
                            {editOpen[Number(row.id)] && <DeleteForm recipe={row} />}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
};
