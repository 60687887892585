import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { FuncButtonWIcon } from '../../../common/buttons/FunctionalButton';
import getTitle from '../../../../common/translations';
import { useLanguage } from '../../../../context/LanguageContext';
import { getStyles } from '../../customerCommon';
import { getMedia } from '../../../../common/mediaQuery';
import { ICustomer } from '../../../../models/Customers/ICustomer';
import { compareObjects, getCustomerName } from '../../../../common/helpers';
import { DTO_STATE } from '../../../../common/dictionary';
import { CustomerValidator } from '../../../../validators/CustomerValidator';
import { saveCustomer } from '../../dataManager';
import { MuiTelInput } from 'mui-tel-input';

interface Props {
    item: ICustomer;
    cancelEdit: (id: string | number) => void;
}

export const UpdateForm = ({ item, cancelEdit }: Props) => {
    const { language } = useLanguage();
    const { inputStylePadding, updateFormStyle, updateFormInput, buttonWrapper, opacityHalf, progressLoader } = getStyles();
    const { isMobile } = getMedia();
    const { save, saveCustomerError, saveCustomerIsSuccess } = saveCustomer();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modified, setModified] = useState<boolean>(false);
    const [isButtonDisable, setIsButtonDisable] = useState<boolean>(true);
    const [updatedData, setUpdatedData] = useState<ICustomer>({ ...item });
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState<'error' | 'success' | undefined>(undefined);
    const [isValidEmail, setIsValidEmail] = useState<'error' | 'success' | undefined>(undefined);
    const [isValidFirstName, setIsValidFirstName] = useState<'error' | 'success' | undefined>(undefined);
    const [isValidLastName, setIsValidLastName] = useState<'error' | 'success' | undefined>(undefined);
    const [isValidInstagram, setIsValidInstagram] = useState<'error' | 'success' | undefined>(undefined);
    const [isValidFacebook, setIsValidFacebook] = useState<'error' | 'success' | undefined>(undefined);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [dialogContent, setDialogContent] = useState<string | undefined>();
    const [isDeleteValueValid, setIsDeleteValueValid] = useState<boolean>(false);
    const [variant, setVariant] = useState<'standard' | 'outlined'>(updatedData.firstName!.length >= 3 ? 'standard' : 'outlined');

    const pulseStyle = {
        '& .MuiOutlinedInput-root': {
            animation: 'pulse 2s infinite',
        },
        '@keyframes pulse': {
            '0%': {
                borderColor: '#b58c3f',
                boxShadow: '0 0 5px #b58c3f',
            },
            '50%': {
                borderColor: '#f54100',
                boxShadow: '0 0 15px #f54100',
            },
            '100%': {
                borderColor: '#e82d04',
                boxShadow: '0 0 5px #e82d04',
            },
        },
    };
    const [firstNameStyle, setFirstNameStyle] = useState(updatedData.firstName!.length > 3 ? updateFormInput : pulseStyle);
    const validator = useMemo(() => CustomerValidator.getInstance(updatedData), [updatedData]);

    const inputFirstNameRef = useRef<HTMLInputElement>(null);
    const inputLastNameRef = useRef<HTMLInputElement>(null);
    const inputEmailRef = useRef<HTMLInputElement>(null);
    const inputPhoneNumberRef = useRef<HTMLInputElement>(null);
    const inputInstagramRef = useRef<HTMLInputElement>(null);
    const inputFaceBookRef = useRef<HTMLInputElement>(null);

    const handleChange = (e: any) => {
        setUpdatedData((prevState) => {
            return {
                ...prevState,
                [e.target.id]: e.target.value,
            };
        });

        if (e.target.id === 'email') {
            const isValid = validator.isValidEmail(e.target.value, true);
            setIsValidEmail(isValid === undefined ? undefined : isValid ? 'success' : 'error');
        }

        if (e.target.id === 'firstName') {
            const isValid = validator.isValidFirstName(e.target.value, true);
            setIsValidFirstName(isValid === undefined ? undefined : isValid ? 'success' : 'error');
        }

        if (e.target.id === 'lastName') {
            const isValid = validator.isValidLastName(e.target.value, true);
            setIsValidLastName(isValid === undefined ? undefined : isValid ? 'success' : 'error');
        }

        if (e.target.id === 'instagram') {
            const isValid = validator.isValidInstagram(e.target.value, true);
            setIsValidInstagram(isValid === undefined ? undefined : isValid ? 'success' : 'error');
        }

        if (e.target.id === 'faceBook') {
            const isValid = validator.isValidFacebook(e.target.value, true);
            setIsValidFacebook(isValid === undefined ? undefined : isValid ? 'success' : 'error');
        }

        setModified(true);
    };

    const handlePhoneNumber = (phoneNumber: any) => {
        const isValid = validator.isValidPhoneNumber(phoneNumber, true);
        setIsValidPhoneNumber(isValid === undefined ? undefined : isValid ? 'success' : 'error');

        setUpdatedData((prevState) => {
            return {
                ...prevState,
                phoneNumber: phoneNumber,
            };
        });
        setModified(true);
    };

    const handleSave = (e: any) => {
        if (e.target.id === 'delete') {
            updatedData.state = DTO_STATE.DTO_DELETED;
        }
        setIsLoading(true);
        save(updatedData);
        // TODO: handle errors and view it in UI. Handle delete error when customer has active order or when update customer and has same customer in DB
    };

    const getDialogContent = () => {
        setDialogContent(getCustomerName(item));
    };

    const checkDeleteValue = (e: any) => {
        if (e.target.value === dialogContent) {
            setIsDeleteValueValid(true);
        } else {
            setIsDeleteValueValid(false);
        }
    };

    useEffect(() => {
        if (saveCustomerIsSuccess) {
            setIsLoading(false);
            cancelEdit(updatedData.id);
        }
    }, [saveCustomerIsSuccess]);

    useEffect(() => {
        if (!modified) {
            return;
        }

        let isCompare = true;

        if (!compareObjects(item, updatedData)) {
            isCompare = false;
            setUpdatedData((prevState) => {
                return {
                    ...prevState,
                    state: DTO_STATE.DTO_MODIFIED,
                };
            });
        } else {
            setUpdatedData((prevState) => {
                return {
                    ...prevState,
                    state: DTO_STATE.DTO_UNCHANGED,
                };
            });
        }

        setModified(false);
        setIsButtonDisable(!(validator.validate() && !isCompare));
    }, [updatedData]);

    useEffect(() => {
        if (inputFirstNameRef.current) {
            inputFirstNameRef.current.focus();
        }
    }, [variant]);

    useEffect(() => {
        if (updatedData.firstName!.length >= 3) {
            setVariant('standard');
            setFirstNameStyle(updateFormInput);
        } else if (updatedData.firstName!.length === 0) {
            setVariant('outlined');
            setFirstNameStyle(pulseStyle);
        }
    }, [updatedData.firstName]);

    useEffect(() => {
        if (dialogOpen) {
            getDialogContent();
        } else {
            setIsDeleteValueValid(false);
        }
    }, [dialogOpen]);

    return (
        <Box sx={{ position: 'relative' }}>
            {isLoading ? (
                <Box sx={{ ...progressLoader, top: '40%' }}>
                    <CircularProgress color="success" />
                </Box>
            ) : (
                <></>
            )}

            <Box sx={isLoading ? opacityHalf : {}}>
                <Box sx={isMobile ? {} : { ...buttonWrapper, mt: '10px', ml: 0 }}>
                    <FuncButtonWIcon
                        field={getTitle(language, 'delete')}
                        handleClick={() => {
                            setDialogOpen(true);
                        }}
                        iconName={'deleteIcon'}
                    />
                </Box>
                <Box sx={isMobile ? {} : { display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={updateFormStyle}>
                        <TextField
                            inputRef={inputFirstNameRef}
                            margin="dense"
                            id="firstName"
                            label={getTitle(language, 'firstName')}
                            placeholder={getTitle(language, 'firstName')}
                            fullWidth
                            focused={isValidFirstName !== undefined}
                            color={isValidFirstName}
                            variant={variant}
                            value={updatedData.firstName}
                            onChange={handleChange}
                            onFocus={() => inputFirstNameRef.current}
                            sx={firstNameStyle}
                        />

                        <TextField
                            inputRef={inputLastNameRef}
                            margin="dense"
                            id="lastName"
                            label={getTitle(language, 'lastName')}
                            placeholder={getTitle(language, 'lastName')}
                            fullWidth
                            focused={isValidLastName !== undefined}
                            color={isValidLastName}
                            variant="standard"
                            value={updatedData.lastName}
                            onChange={handleChange}
                            onFocus={() => inputLastNameRef.current}
                            sx={updateFormInput}
                        />

                        <TextField
                            inputRef={inputEmailRef}
                            margin="dense"
                            id="email"
                            label={getTitle(language, 'email')}
                            placeholder={getTitle(language, 'email')}
                            fullWidth
                            focused={isValidEmail !== undefined}
                            color={isValidEmail}
                            variant="standard"
                            value={updatedData.email}
                            onChange={handleChange}
                            onFocus={() => inputEmailRef.current}
                            sx={inputStylePadding}
                        />
                    </Box>

                    <Box sx={updateFormStyle}>
                        <MuiTelInput
                            margin="dense"
                            id="phone"
                            label={getTitle(language, 'phoneNumber')}
                            placeholder={getTitle(language, 'phoneNumber')}
                            fullWidth
                            focused={isValidPhoneNumber !== undefined}
                            color={isValidPhoneNumber}
                            variant="standard"
                            value={updatedData.phoneNumber}
                            onChange={handlePhoneNumber}
                            onFocus={() => inputPhoneNumberRef.current}
                            defaultCountry={'PL'}
                            //continents={['EU']}
                            onlyCountries={['UA', 'PL', 'DE', 'IE']}
                            forceCallingCode={true}
                        />

                        <TextField
                            inputRef={inputInstagramRef}
                            margin="dense"
                            id="instagram"
                            label={getTitle(language, 'instagram')}
                            placeholder={getTitle(language, 'instagram')}
                            focused={isValidInstagram !== undefined}
                            color={isValidInstagram}
                            fullWidth
                            variant="standard"
                            value={updatedData.instagram}
                            onChange={handleChange}
                            onFocus={() => inputInstagramRef.current}
                            sx={inputStylePadding}
                        />

                        <TextField
                            inputRef={inputFaceBookRef}
                            margin="dense"
                            id="faceBook"
                            label={getTitle(language, 'facebook')}
                            placeholder={getTitle(language, 'facebook')}
                            focused={isValidFacebook !== undefined}
                            color={isValidFacebook}
                            fullWidth
                            variant="standard"
                            value={updatedData.faceBook}
                            onChange={handleChange}
                            onFocus={() => inputFaceBookRef.current}
                            sx={inputStylePadding}
                        />
                    </Box>
                </Box>

                <Box sx={isMobile ? {} : { justifyContent: 'space-between', ...buttonWrapper, mt: '10px', ml: 0 }}>
                    <FuncButtonWIcon disabled={isButtonDisable} field={getTitle(language, 'save')} handleClick={handleSave} iconName={'sendIcon'} />
                </Box>
            </Box>
            <Dialog
                fullWidth
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                }}
            >
                <DialogTitle>Delete customer</DialogTitle>

                <DialogContent>
                    <Box>You definitely want to delete the customer: {dialogContent} ?</Box>
                    <Box>
                        To delete, write in the box below:{' '}
                        <Typography variant={'h6'} component={'span'}>
                            {' '}
                            {dialogContent}
                        </Typography>
                    </Box>
                    <TextField fullWidth color={isDeleteValueValid ? 'success' : 'error'} placeholder={dialogContent} onChange={checkDeleteValue} />
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button id="delete" variant="contained" onClick={handleSave} color="error" disabled={!isDeleteValueValid}>
                        {getTitle(language, 'delete')}
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setDialogOpen(false);
                        }}
                    >
                        {getTitle(language, 'cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
