import { Route, Routes } from 'react-router-dom';
import Items from '../items/Items';
import HomePage from '../homePage/HomePage';
import Orders from '../orders/OrdersHOC';
import Finances from '../finances/Finances';
import RecipesMain from '../recipes/RecipesMain';
import Customers from '../customers/Customers';
import ChaiPage from '../chaiPage/ChaiPage';

function RoutesManager() {
    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/items" element={<Items />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/recipes/*" element={<RecipesMain />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/finances" element={<Finances />} />
            <Route path="/chai" element={<ChaiPage />} />
        </Routes>
    );
}

export default RoutesManager;
