const ORDER = 'Order';
const ITEM = 'Item';
const AUTH = 'Auth';
const CATEGORY = 'Category';
const CUSTOMERS = 'Customers';
const RECIPE = 'Recipe';
const RECIPES = 'Recipes';
const USER = 'User';
const FILLING = 'Filling';
const FILLINGS = 'Fillings';
const RECIPES_LIGHT = 'RecipesLight';
const RECIPES_FOR_ORDER = 'RecipesForOrder';

export const tagTypes = [ORDER, ITEM, AUTH, CATEGORY, CUSTOMERS, RECIPE, RECIPES, USER, FILLING, FILLINGS, RECIPES_LIGHT, RECIPES_FOR_ORDER];
