import { useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress, TextField } from '@mui/material';
import { ICustomer } from '../../models/Customers/ICustomer';
import { DTO_STATE } from '../../common/dictionary';
import { customerAPI } from '../../services/CustomerServices';
import { getCommonStyles } from '../../common/commonStyles';
import getTitle from '../../common/translations';
import { useLanguage } from '../../context/LanguageContext';
import { getStyles } from './customerCommon';
import { CustomerValidator } from '../../validators/CustomerValidator';
import { FuncButtonWIcon } from '../common/buttons/FunctionalButton';
import { MuiTelInput } from 'mui-tel-input';
import { IError } from '../../models/common/IError';

interface CreateCustomerProps {
    handleCreateCustomerDialog: () => void;
    setCreatedCustomer?: (customer: ICustomer) => void;
}

const CreateCustomerNew = ({ handleCreateCustomerDialog, setCreatedCustomer }: CreateCustomerProps) => {
    const [isLoading, setIsLoading] = useState(false);

    const { progressLoader, opacityHalf } = getStyles();
    const { simpleBtnStyle } = getCommonStyles();
    const { language } = useLanguage();
    const [saveCustomer, { error: saveCustomerError, isSuccess: saveCustomerIsSuccess, data: newCustomerData }] = customerAPI.useSaveCustomerMutation();
    const [disableSaveButton, setDisableSaveButton] = useState<boolean>(true);
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState<'error' | 'success' | undefined>(undefined);
    const [isValidEmail, setIsValidEmail] = useState<'error' | 'success' | undefined>(undefined);
    const [isValidFirstName, setIsValidFirstName] = useState<'error' | 'success' | undefined>(undefined);
    const [isValidLastName, setIsValidLastName] = useState<'error' | 'success' | undefined>(undefined);
    const [isValidInstagram, setIsValidInstagram] = useState<'error' | 'success' | undefined>(undefined);
    const [isValidFacebook, setIsValidFacebook] = useState<'error' | 'success' | undefined>(undefined);
    const [existCustomerMessage, setExistCustomerMessage] = useState<string | undefined>();

    const [newCustomer, setNewCustomer] = useState<ICustomer>({
        id: -1,
        firstName: '',
        lastName: '',
        name: '',
        email: '',
        phoneNumber: '',
        instagram: '',
        faceBook: '',
        state: DTO_STATE.DTO_NEW,
    });

    const validator = useMemo(() => CustomerValidator.getInstance(newCustomer), [newCustomer]);

    const handleCustomerFields = (e: any) => {
        setNewCustomer((prevState) => {
            return {
                ...prevState,
                [e.target.id]: e.target.value,
            };
        });

        if (e.target.id === 'email') {
            const isValid = validator.isValidEmail(e.target.value, true);
            setIsValidEmail(isValid === undefined ? undefined : isValid ? 'success' : 'error');
        }

        if (e.target.id === 'firstName') {
            const isValid = validator.isValidFirstName(e.target.value, true);
            setIsValidFirstName(isValid === undefined ? undefined : isValid ? 'success' : 'error');
        }

        if (e.target.id === 'lastName') {
            const isValid = validator.isValidLastName(e.target.value, true);
            setIsValidLastName(isValid === undefined ? undefined : isValid ? 'success' : 'error');
        }

        if (e.target.id === 'instagram') {
            const isValid = validator.isValidInstagram(e.target.value, true);
            setIsValidInstagram(isValid === undefined ? undefined : isValid ? 'success' : 'error');
        }

        if (e.target.id === 'faceBook') {
            const isValid = validator.isValidFacebook(e.target.value, true);
            setIsValidFacebook(isValid === undefined ? undefined : isValid ? 'success' : 'error');
        }
    };

    const handlePhoneNumber = (phoneNumber: any) => {
        const isValid = validator.isValidPhoneNumber(phoneNumber, true);
        setIsValidPhoneNumber(isValid === undefined ? undefined : isValid ? 'success' : 'error');

        setNewCustomer((prevState) => {
            return {
                ...prevState,
                phoneNumber: phoneNumber,
            };
        });
    };

    const handleCreateCustomer = () => {
        setIsLoading(true);
        saveCustomer(newCustomer);
    };

    useEffect(() => {
        if (saveCustomerIsSuccess && newCustomerData) {
            if (setCreatedCustomer !== undefined) {
                setCreatedCustomer(newCustomerData);
            }
            handleCreateCustomerDialog();
            setIsLoading(false);
        }
    }, [saveCustomerIsSuccess, newCustomerData]);

    // TODO: need to add logic for disable save button if customer exist
    //       need to check prev values and if it will be same as before don't enable button
    useEffect(() => {
        setDisableSaveButton(!validator.validate());
        /*if (existCustomerMessage !== undefined) {
            let result = existCustomerMessage.includes(newCustomer.faceBook) || existCustomerMessage.includes(newCustomer.instagram);
        }*/
    }, [newCustomer]);

    useEffect(() => {
        if (saveCustomerError) {
            let err = saveCustomerError as IError;
            if (err.status === 409) {
                if ('data' in err) {
                    setExistCustomerMessage(err.data.message);
                }
            }
            setIsLoading(false);
        }
    }, [saveCustomerError]);

    return (
        <Box sx={{ backgroundColor: '#99999915', padding: '10px', mt: '20px', position: 'relative' }}>
            {isLoading ? (
                <Box sx={progressLoader}>
                    <CircularProgress color="success" />
                </Box>
            ) : (
                <></>
            )}

            <Box sx={isLoading ? opacityHalf : {}}>
                {existCustomerMessage && existCustomerMessage}
                <TextField
                    autoFocus
                    margin="dense"
                    id="firstName"
                    label="First name"
                    type="text"
                    focused={isValidFirstName !== undefined}
                    fullWidth
                    color={isValidFirstName}
                    variant="standard"
                    value={newCustomer.firstName}
                    onChange={handleCustomerFields}
                />
                <TextField
                    margin="dense"
                    id="lastName"
                    label="Last name"
                    type="text"
                    focused={isValidLastName !== undefined}
                    fullWidth
                    color={isValidLastName}
                    variant="standard"
                    value={newCustomer.lastName}
                    onChange={handleCustomerFields}
                />
                <TextField
                    margin="dense"
                    id="email"
                    label="Email"
                    type="text"
                    focused={isValidEmail !== undefined}
                    fullWidth
                    color={isValidEmail}
                    variant="standard"
                    value={newCustomer.email}
                    onChange={handleCustomerFields}
                />
                <MuiTelInput
                    margin="dense"
                    id="phone"
                    label={getTitle(language, 'phoneNumber')}
                    placeholder={getTitle(language, 'phoneNumber')}
                    focused={isValidPhoneNumber !== undefined}
                    fullWidth
                    color={isValidPhoneNumber}
                    variant="standard"
                    value={newCustomer.phoneNumber}
                    onChange={handlePhoneNumber}
                    defaultCountry={'PL'}
                    //continents={['EU']}
                    onlyCountries={['UA', 'PL', 'DE', 'IE']}
                    forceCallingCode={true}
                />
                <TextField
                    margin="dense"
                    id="instagram"
                    label="Instagram"
                    type="text"
                    focused={isValidInstagram !== undefined}
                    fullWidth
                    color={isValidInstagram}
                    variant="standard"
                    value={newCustomer.instagram}
                    onChange={handleCustomerFields}
                />
                <TextField
                    margin="dense"
                    id="faceBook"
                    label="Facebook"
                    type="text"
                    focused={isValidFacebook !== undefined}
                    fullWidth
                    color={isValidFacebook}
                    variant="standard"
                    value={newCustomer.faceBook}
                    onChange={handleCustomerFields}
                />
                <Box sx={{ mt: '20px', mb: '10px' }}>
                    <FuncButtonWIcon disabled={disableSaveButton} field={getTitle(language, 'create')} handleClick={handleCreateCustomer} iconName={'sendIcon'} />
                </Box>
            </Box>
        </Box>
    );
};

export default CreateCustomerNew;
