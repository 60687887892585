import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import getTitle from '../../../common/translations';
import { useEffect, useState } from 'react';
import { FuncButtonWIcon } from '../../common/buttons/FunctionalButton';
import { useLanguage } from '../../../context/LanguageContext';
import { IRecipe } from '../../../models/Recipe/IRecipe';
import { DTO_STATE } from '../../../common/dictionary';
import { saveRecipePost } from '../dataManager';

interface Props {
    recipe: IRecipe;
}

export const DeleteForm = ({ recipe }: Props) => {
    const { language } = useLanguage();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState<string | undefined>();
    const [isDeleteValueValid, setIsDeleteValueValid] = useState<boolean>(false);
    const { saveRecipe, saveRecipeError, isSaveRecipeSuccess } = saveRecipePost();

    const checkDeleteValue = (e: any) => {
        if (e.target.value === dialogContent) {
            setIsDeleteValueValid(true);
        } else {
            setIsDeleteValueValid(false);
        }
    };

    const getDialogContent = () => {
        setDialogContent(recipe.recipeName);
    };

    const handleSave = () => {
        setIsDeleteValueValid(false);
        const _: IRecipe = { ...recipe, state: DTO_STATE.DTO_DELETED };
        saveRecipe(_);
        // TODO: handle and display error when delete recipe. Exist recipe in active order or another error.
    };

    useEffect(() => {
        if (dialogOpen) {
            getDialogContent();
        } else {
            setIsDeleteValueValid(false);
        }
    }, [dialogOpen]);

    useEffect(() => {
        if (isSaveRecipeSuccess) {
            setDialogOpen(false);
        }
    }, [isSaveRecipeSuccess]);

    return (
        <>
            <FuncButtonWIcon fullWidth={true} field={getTitle(language, 'delete')} handleClick={() => setDialogOpen(true)} iconName={'deleteIcon'} />
            <Dialog
                fullWidth
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                }}
            >
                <DialogTitle>Delete recipe</DialogTitle>
                <DialogContent>
                    <Box>You definitely want to delete the recipe: {dialogContent} ?</Box>
                    <Box>
                        To delete, write in the box below:{' '}
                        <Typography variant={'h6'} component={'span'}>
                            {' '}
                            {dialogContent}
                        </Typography>
                    </Box>
                    <TextField fullWidth color={isDeleteValueValid ? 'success' : 'error'} placeholder={dialogContent} onChange={checkDeleteValue} />
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button id="delete" variant="contained" onClick={handleSave} color="error" disabled={!isDeleteValueValid}>
                        {getTitle(language, 'delete')}
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setDialogOpen(false);
                        }}
                    >
                        {getTitle(language, 'cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
