import { Fragment, SetStateAction, useEffect, useId, useState } from 'react';

import { Button, CircularProgress, Collapse, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { EnhancedTableToolbar } from './EnhancedTableToolbar';
import { EnhancedTableHead } from './EnhancedTableHead';
import { getStyles } from '../customerCommon';
import { CLEAR_BUTTON } from '../../../common/dictionary';
import getTitle from '../../../common/translations';
import { useLanguage } from '../../../context/LanguageContext';
import { getMedia } from '../../../common/mediaQuery';
import { ICustomerWithInfos } from '../../../models/Customers/ICustomerWithInfos';
import { CustomerCard } from './CollapseContent/CustomerCard';
import InfoIcon from '@mui/icons-material/Info';
import { UpdateForm } from './CollapseContent/UpdateForm';
import { getCustomerName } from '../../../common/helpers';
import { Order } from '../../items/tableCommon';

type Props = {
    items: ICustomerWithInfos[];
    itemsQtyTotal: number;
    itemsPerPage: number;
    currentPageNumber: number;
    setItemsPerPage: (qty: number) => void;
    setCurrentPageNumber: (page: number) => void;
    isNewDataFetching: boolean;
    setIsNewDataFetching: (arg: boolean) => void;
    setSearchPhrase: (arg: string) => void;
    setSearchInput: (arg: string) => void;
    searchInput: string;
    setOrderBy: (data: SetStateAction<string>) => void;
    orderBy: string;
    order: Order;
    setOrder: (data: SetStateAction<Order>) => void;
};

export function EnhancedCollapsibleTable({
    items,
    currentPageNumber,
    itemsQtyTotal,
    itemsPerPage,
    setItemsPerPage,
    setCurrentPageNumber,
    isNewDataFetching,
    setIsNewDataFetching,
    setSearchPhrase,
    setSearchInput,
    searchInput,
    setOrderBy,
    orderBy,
    order,
    setOrder,
}: Props) {
    const {
        useGetHeaders,
        rowsPerPageOptions,
        debounceTimeValue,
        tableWrapper,
        tablePaperWrapper,
        tableWidthStyle,
        tableRowWrapper,
        tableRowStyle,
        tableModalsWrapper,
        tablePaginationStyle,
        opacityHalf,
        progressLoader,
        tableBoxWrapper,
        formStyle,
        tablePaginationSelectProps,
        tablePagination,
    } = getStyles();
    const uniqueId = useId();
    const headCells = useGetHeaders();

    const { language } = useLanguage();
    // FIXME:
    const { isTablet } = getMedia();

    const [rowOpen, setRowOpen] = useState<Record<number, boolean>>({});
    const [editOpen, setEditOpen] = useState<Record<number, boolean>>({});
    const [debouncedValue, setDebouncedValue] = useState(searchInput);

    const emptyRows = currentPageNumber > 0 ? Math.max(0, (1 + currentPageNumber) * itemsPerPage - items.length) : 0;

    // methods
    const handleClickCollapse = (id: string | number) => {
        const isOpen = !!rowOpen[Number(id)];

        setRowOpen((prevRowOpen) => ({
            ...prevRowOpen,
            [id]: !isOpen,
        }));
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: SetStateAction<string>) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        if (newPage >= 0 && newPage < Math.ceil(itemsQtyTotal / itemsPerPage) && newPage > currentPageNumber) {
            setCurrentPageNumber(newPage);
        } else {
            setCurrentPageNumber(newPage);
        }
        setIsNewDataFetching(true);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItemsPerPage(parseInt(event.target.value, 10));
        setCurrentPageNumber(0);
    };

    const handleChangeSearch = (value: string) => {
        if (value === CLEAR_BUTTON) {
            setSearchInput('');
            setSearchPhrase('');
        } else {
            setSearchInput(value);
        }
    };

    const handleClickEdit = async (id: string | number) => {
        const isEdit = !!editOpen[Number(id)];

        setEditOpen((prevRowOpen) => ({
            ...prevRowOpen,
            [id]: !isEdit,
        }));
    };

    const cancelEdit = (id: string | number) => {
        const isEdit = !!editOpen[Number(id)];

        setEditOpen((prevRowOpen) => ({
            ...prevRowOpen,
            [id]: !isEdit,
        }));
    };

    useEffect(() => {
        const t = setTimeout(() => {
            setDebouncedValue(searchInput);
        }, debounceTimeValue);

        return () => {
            clearTimeout(t);
        };
    }, [searchInput]);

    useEffect(() => {
        setSearchPhrase(debouncedValue);
    }, [debouncedValue]);

    return (
        <Box sx={{ ...tableWrapper, position: 'relative' }}>
            {isNewDataFetching ? (
                <Box sx={progressLoader}>
                    <CircularProgress color="success" />
                </Box>
            ) : (
                <></>
            )}
            <Paper
                sx={{
                    ...tablePaperWrapper,
                    ...tablePaginationStyle,
                    ...(isNewDataFetching ? { opacityHalf } : {}),
                }}
            >
                <EnhancedTableToolbar searchInput={searchInput} handleChangeSearch={handleChangeSearch} />

                <TableContainer>
                    <Table sx={tableWidthStyle} aria-labelledby="tableTitle">
                        <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} visibleColumns={headCells} />
                        <TableBody>
                            {items.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={6}>{getTitle(language, 'no_records')}</TableCell>
                                </TableRow>
                            )}
                            {items.map((row: ICustomerWithInfos, index: number) => {
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <Fragment key={uniqueId + index}>
                                        <TableRow
                                            hover
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleClickCollapse(row.id);
                                            }}
                                            tabIndex={-1}
                                            key={row.id}
                                            sx={{ ...tableRowWrapper, ...(index % 2 && tableRowStyle) }}
                                        >
                                            <TableCell id={labelId} padding="none">
                                                <IconButton aria-label="expand row" size="small">
                                                    {rowOpen[Number(row.id)] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </IconButton>
                                                {getCustomerName(row)}
                                                {row.name === undefined || row.name.length < 3 ? (
                                                    <Tooltip
                                                        placement="top"
                                                        title={
                                                            <div style={{ fontSize: '125%' }}>
                                                                Customer name is empty. Please set customer name. <br />* Name field not required.
                                                            </div>
                                                        }
                                                    >
                                                        <InfoIcon
                                                            sx={{
                                                                fontSize: '150%',
                                                                transition: 'color 1s',
                                                                color: 'rgb(210,25,68)',
                                                                ':hover': {
                                                                    color: 'rgb(234,127,148)',
                                                                },
                                                            }}
                                                        />
                                                    </Tooltip>
                                                ) : (
                                                    ''
                                                )}
                                                {row.isDeleted ? (
                                                    <Box component={'span'} sx={{ color: '#bf0000', fontWeight: 'bold' }}>
                                                        &nbsp;deleted
                                                    </Box>
                                                ) : (
                                                    ''
                                                )}
                                            </TableCell>

                                            <TableCell align="center">{row.totalOrderCount}</TableCell>
                                        </TableRow>

                                        {/* item manager in collapse section under the row */}
                                        <TableRow>
                                            <TableCell style={tableModalsWrapper} colSpan={6}>
                                                <Collapse in={rowOpen[Number(row.id)]} timeout="auto" unmountOnExit>
                                                    <Box>
                                                        <Box sx={tableBoxWrapper}>
                                                            {!editOpen[Number(row.id)] ? (
                                                                <>
                                                                    <Button onClick={() => handleClickEdit(Number(row.id))}>Edit customer</Button>
                                                                    <Box sx={{ formStyle }}>
                                                                        <CustomerCard key={`${row.id}_${row.createdAt}_${row.averageOrderPrice}`} customerData={row} />
                                                                    </Box>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Button onClick={() => handleClickEdit(Number(row.id))}>Cancel edit</Button>
                                                                    <UpdateForm item={items.find((x) => x.id === row.id) || row} cancelEdit={cancelEdit} />
                                                                </>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </Fragment>
                                );
                            })}

                            {(emptyRows > 0 && items?.length === 0) ||
                                (items?.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={itemsQtyTotal}
                    rowsPerPage={itemsPerPage}
                    page={currentPageNumber}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    SelectProps={tablePaginationSelectProps}
                    sx={tablePagination}
                />
            </Paper>
        </Box>
    );
}
