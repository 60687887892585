import { useLocation, useNavigate } from 'react-router-dom';
import { FormControl, NativeSelect, Tab, Tabs, Toolbar, useMediaQuery } from '@mui/material';
import { useLanguage } from '../../context/LanguageContext';
import getTitle from '../../common/translations';

function NavBar() {
    const { language } = useLanguage();
    const history = useNavigate();
    const location = useLocation();
    const isMobile = useMediaQuery('(max-width:800px)');

    const toolbarStyle = { justifyContent: 'center', minHeight: '64px' };

    // navbar for desktop
    const tabStyle = {
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        borderRightWidth: 'thin',
        height: 'auto',
        alignSelf: 'stretch',
        margin: 0,
        flexShrink: 0,
    };

    const fontSize = { fontSize: '18px' };

    const handleChange = (event: any, newValue: string) => {
        history(newValue);
    };

    // Function to determine the active tab
    const getActiveTab = () => {
        if (location.pathname.startsWith('/recipes')) return '/recipes';
        if (location.pathname.startsWith('/orders')) return '/orders';
        if (location.pathname.startsWith('/items')) return '/items';
        if (location.pathname.startsWith('/finances')) return '/finances';
        if (location.pathname.startsWith('/customers')) return '/customers';
        return '/';
    };

    const activeTab = getActiveTab();

    // select for mobile
    const selectStyle = {
        '& select': {
            paddingLeft: '8px',
            paddingRight: '0px',
        },
        '& option': {
            paddingLeft: '8px',
        },
        '& select:focus': {
            backgroundColor: 'transparent',
        },
        '& option:checked': {
            backgroundColor: (theme: { palette: { action: { selected: any } } }) => theme.palette.action.selected,
        },
    };

    const handleMobileChange = (event: any) => {
        history(event.target.value as string);
    };

    return (
        <Toolbar sx={toolbarStyle}>
            {isMobile ? (
                <FormControl fullWidth variant="standard">
                    <NativeSelect id="native-select" value={activeTab} onChange={handleMobileChange} sx={selectStyle}>
                        <option id="home" value="/">
                            {getTitle(language, 'home')}
                        </option>
                        <option id="orders" value="/orders">
                            {getTitle(language, 'orders_navbar')}
                        </option>
                        <option id="items" value="/items">
                            {getTitle(language, 'items')}
                        </option>
                        <option id="recipes" value="/recipes">
                            {getTitle(language, 'recipes')}
                        </option>
                        <option id="customers" value="/customers">
                            {getTitle(language, 'customers_navbar')}
                        </option>
                        <option id="finances" value="/finances">
                            {getTitle(language, 'finances')}
                        </option>
                    </NativeSelect>
                </FormControl>
            ) : (
                <Tabs value={activeTab} onChange={handleChange}>
                    <Tab label={getTitle(language, 'home')} value="/" sx={{ ...tabStyle, ...fontSize }} />
                    <Tab label={getTitle(language, 'orders_navbar')} value="/orders" sx={{ ...tabStyle, ...fontSize }} />
                    <Tab label={getTitle(language, 'items')} value="/items" sx={{ ...tabStyle, ...fontSize }} />
                    <Tab label={getTitle(language, 'recipes')} value="/recipes" sx={{ ...tabStyle, ...fontSize }} />
                    <Tab label={getTitle(language, 'customers_navbar')} value="/customers" sx={{ ...tabStyle, ...fontSize }} />
                    <Tab label={getTitle(language, 'finances')} value="/finances" sx={fontSize} />

                    {/* add this just to avoid MUI error */}
                    <Tab label={getTitle(language, 'chai')} value="/chai" sx={{ ...fontSize, position: 'absolute', top: -1000, left: -1000, zIndex: -1000, opacity: 0 }} />
                </Tabs>
            )}
        </Toolbar>
    );
}

export default NavBar;
