import { useEffect, useState } from 'react';
import { Box, Button, Divider, LinearProgress, Stack } from '@mui/material';
import { useLanguage } from '../../context/LanguageContext';
import getTitle from '../../common/translations';
import { getMedia } from '../../common/mediaQuery';
import { getStyles, Order } from '../items/tableCommon';
import { EnhancedCollapsibleTable } from './table/EnhancedCollapsibleTable';
import { fetchCustomers } from './dataManager';
import CreateCustomerNew from './CreateCustomerNew';
import { ICustomerWithInfos } from '../../models/Customers/ICustomerWithInfos';

// TODO: need to fix order profit in create order component

const Customers = () => {
    const { language } = useLanguage();
    const { isFullHD } = getMedia();
    const { funcButtonWrapper, wrapperItems, boxItemsLoading, buttonItemsStyle, rowsPP } = getStyles();

    const [createRecipe, setCreateCustomer] = useState(false);
    const [isNewDataFetching, setIsNewDataFetching] = useState(false);
    const [initRender, setInitRender] = useState(true);

    // api data state
    const [customersData, setCustomersData] = useState<ICustomerWithInfos[]>([]);
    const [currentPageNumber, setCurrentPageNumber] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(rowsPP);
    const [itemsQtyTotal, setItemsQtyTotal] = useState(1);

    // search state
    const [searchPhrase, setSearchPhrase] = useState('');
    const [searchInput, setSearchInput] = useState('');

    const [orderByName, setOrderByName] = useState(false);
    const [orderByOrdersQty, setByOrderQty] = useState(false);
    const [descending, setDescending] = useState(false);
    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<string>(orderByName ? 'name' : orderByOrdersQty ? 'qty' : '');

    const { customers, getAllCustomersIsLoading, getAllCustomersError, getAllCustomersIsSuccess } = fetchCustomers({
        currentPageNumber,
        itemsPerPage,
        searchPhrase,
        descending,
        orderByName,
        orderByOrdersQty,
    });

    useEffect(() => {
        if (customers) {
            setCustomersData(customers.data);
            setCurrentPageNumber(customers.page);
            setItemsPerPage(customers.pageSize);
            setItemsQtyTotal(customers.totalCount);

            setIsNewDataFetching(false);
        }
    }, [customers]);

    useEffect(() => {
        if (!initRender) {
            setDescending(order === 'desc');
        } else {
            setInitRender(false);
        }
    }, [order]);

    useEffect(() => {
        if (orderBy === 'name') {
            setOrderByName(true);
            setByOrderQty(false);
        } else if (orderBy === 'qty') {
            setOrderByName(false);
            setByOrderQty(true);
        }
    }, [orderBy]);

    return (
        <Box sx={{ ...wrapperItems, ...(isFullHD ? { minHeight: '900px' } : {}) }}>
            <Box sx={funcButtonWrapper}>
                <Stack direction="row" divider={<Divider flexItem />} alignItems="center" justifyContent={'space-evenly'} gap={'5px'}>
                    <Button
                        variant="outlined"
                        onClick={() => setCreateCustomer(!createRecipe)}
                        sx={{
                            ...buttonItemsStyle,
                            margin: '5px',
                            boxShadow: '0px 1px 3px #999',
                            fontWeight: '600',
                            backgroundColor: 'rgba(25, 118, 210, 0.08)',
                        }}
                    >
                        {createRecipe ? `${getTitle(language, 'back_to_customers')}` : `${getTitle(language, 'create_customer')}`}
                    </Button>
                </Stack>
            </Box>

            {getAllCustomersIsLoading && (
                <Box sx={boxItemsLoading}>
                    <LinearProgress />
                </Box>
            )}

            {createRecipe ? (
                <CreateCustomerNew handleCreateCustomerDialog={() => setCreateCustomer(false)} />
            ) : (
                getAllCustomersIsSuccess &&
                !getAllCustomersIsLoading &&
                !getAllCustomersError && (
                    <EnhancedCollapsibleTable
                        items={customersData}
                        itemsQtyTotal={itemsQtyTotal}
                        itemsPerPage={itemsPerPage}
                        currentPageNumber={currentPageNumber}
                        setItemsPerPage={setItemsPerPage}
                        setCurrentPageNumber={setCurrentPageNumber}
                        isNewDataFetching={isNewDataFetching}
                        setIsNewDataFetching={setIsNewDataFetching}
                        setSearchPhrase={setSearchPhrase}
                        setSearchInput={setSearchInput}
                        searchInput={searchInput}
                        setOrderBy={setOrderBy}
                        setOrder={setOrder}
                        order={order}
                        orderBy={orderBy}
                    />
                )
            )}
        </Box>
    );
};

export default Customers;
