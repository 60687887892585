import { useEffect, useState } from 'react';
import { Box, Button, Divider, LinearProgress, Stack } from '@mui/material';
import { itemAPI } from '../../services/ItemServices';
import { stockAPI } from '../../services/StockServices';
import { categoryAPI } from '../../services/CategoryServices';
import MySnackBar from '../common/snackBars/MySnackBar';
import { UNIT_OF_WEIGHT } from '../../common/dictionary';
import getTitle from '../../common/translations';
import { IItem } from '../../models/Items/IItem';
import { useLanguage } from '../../context/LanguageContext';
import { EnhancedCollapsibleTable } from './table/EnhancedCollapsibleTable';
import { CreateItemDialog } from './dialogs/CreateItemDialog';
import { CreateCategoryDialog } from './dialogs/CreateCategoryDialog';
import { errorColor, getStyles, Order, successColor, unitsOfWeight } from './tableCommon';
import { getCommonStyles } from '../../common/commonStyles';
import { getMedia } from '../../common/mediaQuery';

function Items() {
    const { language } = useLanguage();
    const { funcButtonWrapper, wrapperItems, boxItemsLoading, buttonItemsStyle, rowsPP } = getStyles();
    const { simpleBtnStyle } = getCommonStyles();
    const { isFullHD } = getMedia();

    const [newItem, setNewItem] = useState<Partial<IItem>>({ name: '', categories: [], unitOfWeight: '' });
    const [categoryName, setCategory] = useState<string>('');
    const [openCreateItemDialog, setOpenCreateItemDialog] = useState<boolean>(false);
    const [openCreateCategoryDialog, setOpenCreateCategoryDialog] = useState<boolean>(false);
    const [checked, setChecked] = useState<number[]>([0]);
    const [openSnackBar, setSnackBarOpen] = useState<boolean>(false);
    const [isNewDataFetching, setIsNewDataFetching] = useState(false);

    // api data state
    const [itemsData, setItemsData] = useState<IItem[]>([]);
    const [currentPageNumber, setCurrentPageNumber] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(rowsPP);
    const [itemsQtyTotal, setItemsQtyTotal] = useState(1);

    // search state
    const [searchPhrase, setSearchPhrase] = useState('');
    const [searchInput, setSearchInput] = useState('');

    // sort state
    const [descending, setDescending] = useState(false);
    const [orderByName, setOrderByName] = useState(false);
    const [orderByCount, setOrderByCount] = useState(false);
    const [orderByPrice, setOrderByPrice] = useState(false);
    const [orderByWeight, setOrderByWeight] = useState(false);
    const [categoryQuery, setCategoryQuery] = useState('');

    const [categoriesAvailable, setCategoriesAvailable] = useState(false);
    const [orderBy, setOrderBy] = useState<string>(orderByName ? 'name' : orderByCount ? 'count' : orderByPrice ? 'lastPrice' : orderByWeight ? 'totalWeight' : '');
    const [order, setOrder] = useState<Order>('desc');
    const [catList, setCatList] = useState('');
    const [debouncedCatValue, setDebouncedCatValue] = useState(catList);

    // api
    const {
        data: items,
        error: getAllItemsError,
        isLoading: getAllItemsIsLoading,
        isSuccess: getAllItemsIsSuccess,
    } = itemAPI.useGetItemsByPagesQuery({
        page: currentPageNumber,
        limit: itemsPerPage,
        searchPhrase,
        categories: categoryQuery,
        descending,
        orderByName,
        orderByCount,
        orderByPrice,
        orderByWeight,
    });
    const { data: categories, error: categoriesError, isLoading: categoriesIsLoading, isSuccess: categoriesIsSuccess } = categoryAPI.useGetAllCategoriesQuery();

    const [createItem, { error: createItemError, isSuccess: createItemIsSuccess }] = itemAPI.useCreateItemMutation();
    const [deleteItem, { error: deleteItemError, isSuccess: deleteItemSuccess }] = itemAPI.useDeleteItemMutation();
    const [renameItem, { error: renameItemError, isSuccess: renameItemSuccess }] = itemAPI.useRenameItemMutation();
    const [addItemCategory, { error: addItemCategoryError, isSuccess: addItemCategorySuccess }] = itemAPI.useAddItemCategoryMutation();
    const [addStock, { error: addStockError, isSuccess: addStockSuccess }] = stockAPI.useAddStockMutation();
    const [createCategory, { error: createCategoryError, isSuccess: createCategoryIsSuccess }] = categoryAPI.useCreateCategoryMutation();

    // TODO: need to refactoring order handlers
    const handleOrderByName = () => {
        setOrderByName(true);
        setOrderByCount(false);
        setOrderByPrice(false);
        setOrderByWeight(false);
    };

    const handleOrderByCount = () => {
        setOrderByName(false);
        setOrderByCount(true);
        setOrderByPrice(false);
        setOrderByWeight(false);
    };

    const handleOrderByPrice = () => {
        setOrderByName(false);
        setOrderByCount(false);
        setOrderByPrice(true);
        setOrderByWeight(false);
    };

    const handleOrderByWeight = () => {
        setOrderByName(false);
        setOrderByCount(false);
        setOrderByPrice(false);
        setOrderByWeight(true);
    };

    // methods
    const handleCreateItem = async () => {
        newItem.categories = [...checked];
        await createItem(newItem);
        setNewItem({ name: '', categories: [], unitOfWeight: '' });
        setChecked([0]);
    };

    const handleCreateCategory = async () => {
        await createCategory(categoryName);
        setCategory('');
        handelCreateCategoryDialog();
    };

    const handleRemove = async (id: number | undefined) => {
        deleteItem(id);
    };

    const handleNewItem = (e: { target: { id: any; value: any } }) => {
        setNewItem((prevState) => {
            return {
                ...prevState,
                [e.target.id]: e.target.value,
            };
        });
    };

    const handelCreateItemDialog = () => {
        setOpenCreateItemDialog(!openCreateItemDialog);
    };

    const handelCreateCategoryDialog = () => {
        setOpenCreateCategoryDialog(!openCreateCategoryDialog);
    };

    const handleClose = (e?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBarOpen(false);
    };

    const handleUnitOfWeight = (e: React.SyntheticEvent<Element, Event>, unit: UNIT_OF_WEIGHT | null) => {
        if (unit) {
            setNewItem((prevState) => {
                return {
                    ...prevState,
                    unitOfWeight: unit,
                };
            });
        }
    };

    // effects
    useEffect(() => {
        if (getAllItemsIsSuccess && !getAllItemsIsLoading && !getAllItemsError) setSnackBarOpen(true);
        if (!getAllItemsIsLoading && getAllItemsError) setSnackBarOpen(true);
        if (createItemIsSuccess && !createItemError) setSnackBarOpen(true);
    }, [items, getAllItemsIsSuccess, getAllItemsIsLoading, getAllItemsError, createItemIsSuccess, createItemError]);

    useEffect(() => {
        if (createItemIsSuccess) {
            handelCreateItemDialog();
        }
    }, [createItemIsSuccess]);

    useEffect(() => {
        orderBy === 'name' && handleOrderByName();
        orderBy === 'count' && handleOrderByCount();
        orderBy === 'lastPrice' && handleOrderByPrice();
        orderBy === 'totalWeight' && handleOrderByWeight();
    }, [orderBy]);

    useEffect(() => {
        setDescending(order === 'desc');
    }, [order]);

    useEffect(() => {
        if (items) {
            setItemsData(items.data);
            setCurrentPageNumber(items.page);
            setItemsPerPage(items.pageSize);
            setItemsQtyTotal(items.totalCount);
            setIsNewDataFetching(false);
        }
    }, [items]);

    useEffect(() => {
        const filtered = categories?.filter((el) => el.isDeleted === 0) || [];
        if (filtered.length > 0) {
            setCategoriesAvailable(true);
        }
        if (filtered.length === 0) {
            setCategoriesAvailable(false);
        }
    }, [categories]);

    useEffect(() => {
        const t = setTimeout(() => {
            setDebouncedCatValue(catList);
        }, 1000);

        return () => {
            clearTimeout(t);
        };
    }, [catList]);

    useEffect(() => {
        setCategoryQuery(debouncedCatValue);
    }, [debouncedCatValue]);

    return (
        <Box sx={{ ...wrapperItems, ...(isFullHD ? { minHeight: '900px' } : {}) }}>
            {!openCreateItemDialog && !openCreateCategoryDialog && (
                <>
                    <Box sx={funcButtonWrapper}>
                        <Stack direction="row" divider={<Divider flexItem />} alignItems="center" justifyContent={'space-evenly'} gap={'5px'}>
                            {!openCreateItemDialog || !openCreateCategoryDialog ? (
                                <>
                                    <Button
                                        variant="outlined"
                                        onClick={handelCreateItemDialog}
                                        sx={{
                                            ...buttonItemsStyle,
                                            ...simpleBtnStyle,
                                        }}
                                    >
                                        {getTitle(language, 'createItem')}
                                    </Button>

                                    <Button
                                        variant="outlined"
                                        onClick={handelCreateCategoryDialog}
                                        sx={{
                                            ...buttonItemsStyle,
                                            ...simpleBtnStyle,
                                        }}
                                    >
                                        {getTitle(language, 'create_category')}
                                    </Button>
                                </>
                            ) : (
                                <></>
                            )}
                        </Stack>
                    </Box>

                    <Box>
                        {getAllItemsIsLoading && (
                            <Box sx={boxItemsLoading}>
                                <LinearProgress />
                            </Box>
                        )}

                        {getAllItemsIsSuccess && !getAllItemsIsLoading && !getAllItemsError && (
                            <EnhancedCollapsibleTable
                                items={itemsData}
                                categories={categories}
                                categoriesAvailable={categoriesAvailable}
                                renameItem={renameItem}
                                remove={handleRemove}
                                addItemCategory={addItemCategory}
                                addStock={addStock}
                                itemsQtyTotal={itemsQtyTotal}
                                itemsPerPage={itemsPerPage}
                                currentPageNumber={currentPageNumber}
                                setItemsPerPage={setItemsPerPage}
                                setCurrentPageNumber={setCurrentPageNumber}
                                isNewDataFetching={isNewDataFetching}
                                setIsNewDataFetching={setIsNewDataFetching}
                                setSearchPhrase={setSearchPhrase}
                                setSearchInput={setSearchInput}
                                searchInput={searchInput}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                order={order}
                                setOrder={setOrder}
                                setCatList={setCatList}
                                catList={catList}
                            />
                        )}
                    </Box>
                </>
            )}

            {openCreateItemDialog ? (
                <CreateItemDialog
                    handelCreateItemDialog={handelCreateItemDialog}
                    newItemName={newItem.name}
                    handleNewItem={handleNewItem}
                    unitsOfWeight={unitsOfWeight}
                    handleUnitOfWeight={handleUnitOfWeight}
                    categories={categories}
                    checked={checked}
                    setChecked={setChecked}
                    handleCreateItem={handleCreateItem}
                />
            ) : (
                <></>
            )}
            {openCreateCategoryDialog ? (
                <CreateCategoryDialog
                    handelCreateCategoryDialog={handelCreateCategoryDialog}
                    categoryName={categoryName}
                    setCategory={setCategory}
                    handleCreateCategory={handleCreateCategory}
                />
            ) : (
                <></>
            )}

            {/* TODO: make some update to avoid extra rendering on modal show, watch it, track the impact of this on productivity */}
            {!getAllItemsIsLoading && !getAllItemsError && getAllItemsIsSuccess && (
                <MySnackBar duration={5000} openSnackBar={openSnackBar} handleClose={handleClose} severity={successColor} alertText="All Items fetched!" />
            )}
            {!getAllItemsIsLoading && getAllItemsError && (
                <>
                    <MySnackBar duration={5000} openSnackBar={openSnackBar} handleClose={handleClose} severity={errorColor} alertText="Error fetched Items!" />
                    <h1>Error fetched Items!</h1>
                </>
            )}
            {!createItemError && createItemIsSuccess && (
                <MySnackBar duration={5000} openSnackBar={openSnackBar} handleClose={handleClose} severity={successColor} alertText="Created item!" />
            )}
            {createItemError && !createItemIsSuccess && (
                <MySnackBar duration={5000} openSnackBar={openSnackBar} handleClose={handleClose} severity={errorColor} alertText="Create item error!" />
            )}
        </Box>
    );
}

export default Items;
