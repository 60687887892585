import React, { FC } from 'react';
import { Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Close';
import { getCommonStyles } from '../../../common/commonStyles';

interface ButtonProps {
    field: string;
    fullWidth?: boolean | undefined;
    handleClick: (event: React.MouseEvent) => void;
    iconName?: string;
    style?: {
        lineHeight?: string;
    };
    disabled?: boolean;
}

export const FuncButtonWIcon: FC<ButtonProps> = ({ field, fullWidth, handleClick, iconName, disabled }) => {
    const { simpleBtnStyle } = getCommonStyles();

    let icon = null;

    if (iconName === 'sendIcon') {
        icon = <SendIcon />;
    }

    if (iconName === 'deleteIcon') {
        icon = <DeleteIcon />;
    }

    if (iconName === 'cancelIcon') {
        icon = <CancelIcon />;
    }

    const iconStyle =
        field && !fullWidth
            ? {
                  width: '130px',
                  minWidth: '130px',
              }
            : field && fullWidth
              ? {}
              : {
                    '& .MuiButton-endIcon': {
                        margin: 0,
                    },
                    width: '40px',
                    minWidth: '40px',
                };

    return (
        <Button
            size="small"
            fullWidth={fullWidth}
            variant="outlined"
            disabled={disabled}
            color={iconName === 'deleteIcon' ? 'error' : 'primary'}
            sx={{
                ...simpleBtnStyle,
                ...iconStyle,
                margin: '0px',

                ...(iconName === 'deleteIcon' && { backgroundColor: '#bf000010' }),
            }}
            onClick={handleClick}
            endIcon={icon}
        >
            {field}
        </Button>
    );
};
