import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { ENDPOINTS } from '../common/endpoints';
import { tagTypes } from './TagTypes';

export const baseAPI = createApi({
    reducerPath: 'baseAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: ENDPOINTS.BASE_API_URL,
        credentials: 'include',
    }),
    tagTypes,

    endpoints: (build) => ({}),
});
