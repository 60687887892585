import { useState } from 'react';
import { IconButton, InputAdornment, TextField, Toolbar } from '@mui/material';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import getTitle from '../../../common/translations';
import { useLanguage } from '../../../context/LanguageContext';
import { getStyles } from '../recipeCommon';
import { getMedia } from '../../../common/mediaQuery';
import { CLEAR_BUTTON } from '../../../common/dictionary';
import { IRecipe } from '../../../models/Recipe/IRecipe';

interface EnhancedTableToolbarProps {
    searchInput: string;
    handleChangeSearch: (value: string) => void;
    setIsAllRecipesIsLoading: (arg: boolean) => void;
    items: IRecipe[];
}

export function EnhancedTableToolbar({ searchInput, handleChangeSearch, setIsAllRecipesIsLoading, items }: EnhancedTableToolbarProps) {
    const { language } = useLanguage();
    const { toolbarWrapper, inputStyle } = getStyles();
    // FIXME
    const { isTablet } = getMedia();
    const [isFocus, setIsFocus] = useState(false);

    return (
        <Toolbar sx={toolbarWrapper}>
            <TextField
                // autoFocus={searchInput.length > 0 && isFocus}
                id={'searchInput'}
                label={getTitle(language, 'search')}
                variant="standard"
                value={searchInput}
                onChange={(e) => handleChangeSearch(e.target.value)}
                onBlur={() => setIsFocus(false)}
                onFocus={() => setIsFocus(true)}
                sx={{ ...inputStyle, '.MuiInputBase-input': { pr: '0px' } }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {searchInput && (
                                <IconButton aria-label="clear search" onClick={(e) => handleChangeSearch(CLEAR_BUTTON)} edge="end" sx={{ mr: '0px', ml: '0px' }}>
                                    <SearchOffIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
        </Toolbar>
    );
}
