import { customerAPI } from '../../services/CustomerServices';

export function fetchAllCustomers() {
    const { data: customers, isLoading: getAllCustomersIsLoading, error: getAllCustomersError, isSuccess: getAllCustomersIsSuccess } = customerAPI.useGetAllCustomersQuery();

    return {
        customers,
        getAllCustomersIsLoading,
        getAllCustomersError,
        getAllCustomersIsSuccess,
    };
}

export function fetchCustomers({
    currentPageNumber,
    itemsPerPage,
    searchPhrase,
    descending,
    orderByName,
    orderByOrdersQty,
}: {
    currentPageNumber: number;
    itemsPerPage: number;
    searchPhrase: string;
    descending: boolean;
    orderByName: boolean;
    orderByOrdersQty: boolean;
}) {
    const {
        data: customers,
        isLoading: getAllCustomersIsLoading,
        error: getAllCustomersError,
        isSuccess: getAllCustomersIsSuccess,
    } = customerAPI.useGetCustomersQuery({
        page: currentPageNumber,
        limit: itemsPerPage,
        searchPhrase,
        descending,
        orderByName,
        orderByOrdersQty,
    });

    return {
        customers,
        getAllCustomersIsLoading,
        getAllCustomersError,
        getAllCustomersIsSuccess,
    };
}

export function updateCustomer() {
    const [updateItem, { error: updateCustomerError, isSuccess: updateCustomerIsSuccess }] = customerAPI.useUpdateCustomerMutation();

    return {
        updateItem,
        updateCustomerError,
        updateCustomerIsSuccess,
    };
}

export function deleteCustomer() {
    const [deleteItem, { error: deleteCustomerError, isSuccess: deleteCustomerIsSuccess }] = customerAPI.useDeleteCustomerMutation();

    return {
        deleteItem,
        deleteCustomerError,
        deleteCustomerIsSuccess,
    };
}

export function saveCustomer() {
    const [save, { error: saveCustomerError, isSuccess: saveCustomerIsSuccess }] = customerAPI.useSaveCustomerMutation();

    return {
        save,
        saveCustomerError,
        saveCustomerIsSuccess,
    };
}
