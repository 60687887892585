import { useState } from 'react';
import { IconButton, InputAdornment, TextField, Toolbar } from '@mui/material';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import getTitle from '../../../common/translations';
import { useLanguage } from '../../../context/LanguageContext';
import { CLEAR_BUTTON } from '../../../common/dictionary';
import { getStyles } from '../customerCommon';

interface EnhancedTableToolbarProps {
    searchInput: string;
    handleChangeSearch: (value: string) => void;
}

export function EnhancedTableToolbar({ searchInput, handleChangeSearch }: EnhancedTableToolbarProps) {
    const { language } = useLanguage();
    const { inputStyle } = getStyles();

    const [isFocus, setIsFocus] = useState(false);

    return (
        <Toolbar /*sx={toolbarWrapper}*/>
            <TextField
                autoFocus={searchInput.length > 0}
                id={'searchInput'}
                label={getTitle(language, 'search')}
                variant="standard"
                value={searchInput}
                onChange={(e) => handleChangeSearch(e.target.value)}
                onBlur={() => setIsFocus(false)}
                onFocus={() => setIsFocus(true)}
                sx={{ ...inputStyle, '.MuiInputBase-input': { pr: '0px' }, pr: '8px' }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {searchInput && (
                                <IconButton aria-label="clear search" onClick={(e) => handleChangeSearch(CLEAR_BUTTON)} edge="end" sx={{ mr: '0px', ml: '0px' }}>
                                    <SearchOffIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
        </Toolbar>
    );
}
