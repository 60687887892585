import { DTO_STATE } from '../common/dictionary';
import { IOrder } from '../models/Order/IOrder';
import { IPrepayment } from '../models/Order/IPrepayment';
import { ISubOrder } from '../models/Order/ISubOrder';
import { IRecipe } from '../models/Recipe/IRecipe';
import { IOrderValidator } from './interfaces/IOrderValidator';

export class OrderValidator implements IOrderValidator {
    private order: IOrder;
    private recipes: IRecipe[];
    private static instance: OrderValidator;

    private constructor(order: IOrder, recipes: IRecipe[]) {
        this.order = order;
        this.recipes = recipes;
    }

    private setObjects(order: IOrder, recipes: IRecipe[]) {
        this.order = order;
        this.recipes = recipes;
    }

    public static getInstance(order: IOrder, recipes: IRecipe[]): IOrderValidator {
        if (!OrderValidator.instance) {
            OrderValidator.instance = new OrderValidator(order, recipes);
        } else {
            OrderValidator.instance.setObjects(order, recipes);
        }

        return OrderValidator.instance;
    }

    public isValidSubOrders(subOrders?: ISubOrder[]): boolean {
        let isValid = true;
        const so = subOrders || this.order.subOrders;

        so?.forEach((el) => {
            if (!isValid) {
                return;
            }
            if (isValid && (el.state === DTO_STATE.DTO_UNCHANGED || el.state === DTO_STATE.DTO_DELETED)) {
                return;
            }
            const r = this.recipes.find((e) => e.id === el.recipeId);

            if (isValid && el.recipeName === '') {
                isValid = false;
            }

            if (isValid && el.recipeId === 0) {
                isValid = false;
            }

            if (isValid && el.price === 0) {
                isValid = false;
            }

            if (isValid && el.count === 0) {
                isValid = false;
            }

            if (isValid && el.recipeCounter === 0) {
                isValid = false;
            }

            if (isValid && r && r.fillings.length > 0 && el.fillingId === 0) {
                isValid = false;
            }
        });

        return isValid;
    }

    public isValidPrepayment(prepayment?: IPrepayment): boolean {
        let isValid = true;
        const p = prepayment || this.order.prepayment;
        if (this.order.state === DTO_STATE.DTO_NEW || p?.state === DTO_STATE.DTO_UNCHANGED) {
            return true;
        }
        if (isValid && p?.prepayment === 0) {
            isValid = false;
        }
        if ((isValid && p?.prepaymentAt === '0001-01-01') || p?.prepaymentAt === '') {
            isValid = false;
        }

        return isValid;
    }

    public isValidOrderFields(order?: IOrder): boolean {
        let isValid = true;
        const o = order || this.order;
        if (o?.price === 0) {
            isValid = false;
        }
        if (o?.customerId === 0) {
            isValid = false;
        }
        if (o?.pickUpAt === '' || !o?.pickUpAt) {
            isValid = false;
        }

        return isValid;
    }

    public validate(disableConsole?: boolean): boolean {
        const results: boolean[] = [this.isValidOrderFields(), this.isValidSubOrders(), this.isValidPrepayment()];
        const isValidOrder = results.every((el) => el);

        if (process.env.NODE_ENV !== 'production' && !disableConsole) {
            console.log('Is valid order fields: ' + results[0]);
            console.log('Is valid suborders: ' + results[1]);
            console.log('Is valid prepayment: ' + results[2]);
            console.log('Is valid order: ' + isValidOrder);
        }

        return isValidOrder;
    }
}
